import event from "../service/event.js"
(function () {
  'use strict';

  const directive = { name: 'removeClassOnEvent' };

  controller.$inject = ['event'];

  function controller(_event) {
    function link(scope, element, attrs) {
      const { event, class: classNames, selector } = scope.$eval(attrs[directive.name]);

      element.on(event || 'click', () => {
        const targetElements = selector ? document.querySelectorAll(selector) : [element[0]];
        const classNamesAsArray = classNames?.trim().split(' ') || [];

        targetElements.forEach((targetElement) => {
          targetElement.classList.remove(...classNamesAsArray);
        });
      });

      _event.create(event, element[0]);
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
