import user from '../../service/domain/user.js';
(function () {
  'use strict';

  controller.$inject = ['user', '$q'];

  function controller(_user, $q) {
    return {
      request: function (config) {
        if (config.meta && config.meta.type === 'api') {
          switch (_user.sessionToken) {
            case '':
              break;

            case 'null':
              break;

            default:
              if (_user.sessionToken && _user.sessionToken.length >= 10)
                config.headers.Authorization = 'Basic ' + _user.sessionToken;
              break;
          }

          //config.headers.Authorization = _user.sessionToken && _user.sessionToken.length ? 'Basic ' + _user.sessionToken : '';
        }

        return config;
      },

      response: function (response) {
        return response;
      },
    };
  }

  app.factory('auth', controller);
})();
