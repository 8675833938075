import config from '../../service/configs/config.js';
(function () {
  'use strict';

  controller.$inject = ['config', '$window', '$interval'];

  function controller(_config, $window, $interval) {
    const events = $_CONFIG.p161 || null;
    const collectedEvents = [];

    const interval = $interval(() => {
      if ($window.p161 && typeof $window.p161.track === 'function' && typeof $window.p161.trackQueue === 'function') {
        collectedEvents.forEach((event) => {
          $window.p161.track(event);
          $window.p161.trackQueue();
        });
        collectedEvents.length = 0;
      }
    }, 2000);

    if (!events) {
      clearInterval(interval);
    }

    return {
      track(eventName) {
        if (events && events[eventName]) collectedEvents.push(events[eventName]);
      },
    };
  }

  app.factory('p161', controller);
})();
