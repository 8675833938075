import config from "../service/configs/config.js"
import user from "../service/domain/user.js"
import system$ from "../service/rx/system$.js"
import system from "../service/domain/system.js"
import { filter } from 'rxjs/operators'
(function () {
  'use strict';

  const directive = { name: 'showElementIf' };
  controller.$inject = ['config', 'user', 'system$', 'system'];

  function controller(_config, _user, _system$, _system) {
    function link(scope, element, attrs) {
      const params = scope.$eval(attrs[directive.name]) || {};
      const langs = params.langs?.map((lang) => lang.toLowerCase()) || [];
      const countries = params.countries?.map((country) => country.toLowerCase()) || [];
      const { currentLang } = _config;

      let subscription;
      const hideElement = () => (element[0].style.display = 'none');
      const showElement = () => (element[0].style.display = '');

      if (langs.includes(currentLang)) {
        showElement();
        return;
      }

      hideElement();

      if (countries.length) {
        showByCountries();

        subscription = _system$
          .pipe(filter(({ action }) => action === 'statusChanged'))
          .subscribe(() => {
            showByCountries();
          });
      }

      function showByCountries() {
        if (_user.status) {
          const userCountryCode = _user.profile.country.toLowerCase();
          countries.includes(userCountryCode) ? showElement() : hideElement();
          return;
        }

        _system
          .info()
          .then(({ result }) => {
            const userCountryCode = result.country.code.toLowerCase();
            countries.includes(userCountryCode) ? showElement() : hideElement();
          })
          .catch(() => {});
      }

      scope.$on('$destroy', () => subscription?.unsubscribe());
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
