import provider from '../../service/domain/provider.js';
(function () {
  'use strict';

  const component = { name: 'lindaProviderList' };
  controller.$inject = ['$scope', 'provider', '$state'];

  function controller($scope, _provider, $state) {
    $scope.collection = [];
    $scope.preloader = true;

    $scope.selected = null;

    _provider.collection().then(
      (a) => {
        $scope.collection = a.result;
        getTitle($state.params.pageName, $state.params.name);
        $scope.preloader = false;
      },
      (a) => {
        $scope.preloader = false;
      }
    );

    function getTitle(page, name) {
      $scope.selected =
        page === 'providers' ? $scope.collection.find((obj) => obj.name === name).title : 'providers_list.title';
    }

    $scope.$on('$stateChangeStart', (event, toState, toParams) => {
      getTitle(toParams.pageName, toParams.name);
    });
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
  });
})();
