import countryLayout from '../service/configs/country-layout.js';
import countryLayout$ from '../service/rx/country-layout$.js';

(function () {
  'use strict';

  const directive = { name: 'getCountryLayoutToScope' };

  controller.$inject = ['countryLayout', 'countryLayout$'];

  function controller(_countryLayout, _countryLayout$) {
    function link($scope, element, attrs) {
      let variable = attrs[directive.name] || 'countryLayout';

      $scope[variable] = _countryLayout.countryConfig;

      let subscription = _countryLayout$.subscribe((data) => {
        $scope[variable] = data;
      });

      $scope.$on('$destroy', () => {
        subscription.unsubscribe();
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }
  app.directive(directive.name, controller);
})();
