import {bonus} from "../../../service/domain/bonus.js";

(function () {
  controller.$inject = ['$scope', 'popup', 'state', 'bonus'];

  function controller($scope, _popup, _state, _bonus) {
    $scope.bonus = {};
    $scope.successAlias = '';
    $scope.preloader = false;

    $scope.activate = () => {
      if (typeof $scope.bonus.bonus_id === 'number') {
        $scope.preloader = true;
        _bonus.activate($scope.bonus, $scope.successAlias)
          .finally(() => {
            $scope.preloader = false;
          })
      }
    };

    $scope.cancel = () => {
      if (typeof $scope.bonus.bonus_id === 'number') {
        $scope.preloader = true;
        _bonus.cancel($scope.bonus)
          .finally(() => {
            $scope.preloader = false;
          });
      }
    };

    this.$onChanges = () => {
      $scope.bonus = this.bonus;
      $scope.successAlias = this.successAlias;
    };
  }

  new Controller('bonusMoneyItem', controller, {
    bonus: '<',
    successAlias: '<',
  });
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanBonusMoneyItem${i}`, 'bonusMoneyItem');
  });
})();
