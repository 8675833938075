import config from '../../service/configs/config.js';
import state from '../../service/domain/state.js';
import banner from '../../service/domain/banner.js';
import game from '../../service/domain/game.js';
import popup from '../../service/domain/popup.js';
import user from '../../service/domain/user.js';
import account from '../../service/domain/account.js';
import system$ from '../../service/rx/system$.js';
import analytics from '../../service/analytics/analytics.js';
(function () {
  'use strict';

  const directive = { name: 'clawGameField' };
  controller.$inject = [
    '$state',
    'system$',
    'config',
    'state',
    'banner',
    'game',
    'popup',
    'user',
    'account',
    'analytics',
  ];

  function controller($state, _system$, _config, _state, _banner, _game, _popup, _user, _account, _analytics) {
    const link = (scope, element) => {
      let crab = {
        destroy: () => {},
      };
      init();

      function init() {
        crab.destroy();

        scope.preloader = true;
        scope.clawGame = { title: '' };

        const PROMISE_LIST = [];
        const game_code = $state.params.name || 'cash_crab';
        let GAME_MODE = null;

        switch (game_code) {
          case 'cash-crab':
            GAME_MODE = 'bonus';
            scope.clawGame.title = 'Bonus crab';
            break;
          case 'oktoberfest':
            GAME_MODE = 'cash';
            scope.clawGame.title = 'Oktoberfest';
            break;
          default:
            GAME_MODE = 'cash';
            scope.clawGame.title = 'Cash crab';
            break;
        }

        let PROMISE_SDK = Promise.resolve(true);
        PROMISE_LIST[0] = PROMISE_SDK;

        if (typeof CrabLoader === 'undefined') {
          const script = document.createElement('script');
          script.src = `/joxi/js/crab/sdk/launch.v2.js?t=${Date.now()}`;
          document.body.appendChild(script);
          PROMISE_SDK = new Promise((resolve) => {
            script.onload = () => resolve(true);
          });
        }

        const PROMISE_LAUNCH = _game.launch({ code: game_code, mode: 'real' });
        PROMISE_LIST[1] = PROMISE_LAUNCH;

        if (GAME_MODE === 'bonus') {
          const PROMISE_BANNERS = _banner.collection({ out: 'images,text', place: 'cash_crab' });
          PROMISE_LIST[2] = PROMISE_BANNERS;
        }

        // PROMISE_LIST === [sdk, launch, banners(opt)]
        Promise.all(PROMISE_LIST).then((RESPONSE) => {
          scope.preloader = false;

          const launch = RESPONSE[1].result;
          const prizes = GAME_MODE === 'bonus' ? mapPrizes(RESPONSE[2].result) : [];

          const GENERAL_LAUNCH_OPTIONS = {
            brand: _config.siteName,
            locale: _config.currentLang,
            brandProfile: _user.profile,
            brandFeature: _config.feature,
            destroyOnLocationChange: false,
            onClickTerms: () => {
              const url = $state.href(_state.get('terms').name);
              window.open(url, '_blank');
            },
            onClickDepositBtn: () => {
              _popup.open({ name: 'cashbox' });
            },
            goToGame: (gameCode = 'cash_crab') => {
              _state.goto('claw', { name: gameCode });
            },
          };

          const BONUS_LAUNCH_OPTIONS =
            GAME_MODE === 'bonus'
              ? {
                  prizes,
                  onRoundEnd: () => {
                    _account.info({ nocache: true });
                  },
                  onClickLoseBtn: () => {
                    _state.goto('home');
                  },
                  onClickBetModeBtn: () => {
                    _state.goto('claw', { name: 'cash_crab' });
                  },
                }
              : {};

          const CASH_LAUNCH_OPTIONS =
            GAME_MODE === 'cash'
              ? {
                  mode: 'MONEY',
                }
              : {};

          const launch_options = Object.assign(
            GENERAL_LAUNCH_OPTIONS,
            CASH_LAUNCH_OPTIONS,
            BONUS_LAUNCH_OPTIONS,
            launch.launch_options,
          );

          window.CrabLoader({ name: 'game', version: '1.0.0' }).then((Crab) => {
            crab = new Crab(element[0], launch_options);
            crab.on('deposit', () => {
              _state.goto('deposit');
            });
            crab.on('gameProvider', (data) => {
              _state.goto('gameProvider', { name: data.providerAlias });
            });
            crab.on('gamePage', (data) => {
              _state.goto('realGame', { name: data.gameAlias });
            });
            crab.on('promotion', () => {
              _state.goto('promotions');
            });
            crab.on('bonuses', () => {
              _state.goto('bonuses');
            });
            crab.on('support', () => {
              _system$.next({ action: 'open chat' });
            });
            crab.on('sportbook', () => {
              _state.goto('sport');
            });
            crab.on('analytics', ({ category, action, label }) => {
              _analytics.send(category, action, label);
            });
          });
        });

        function mapPrizes(p) {
          return p.map((p) => ({
            alias: p.text[0],
            image: `${_config.apiUrl}${p.images.list[0]}`,
            name: p.text[1],
            amount: p.text[2],
            description: p.text[3],
            buttonText: p.text[4],
            onClick: () => {
              _state.goto(p.text[5]);
            },
          }));
        }
      }

      scope.$on('$destroy', () => {
        crab.destroy();
      });
    };

    return { link };
  }

  app.directive(directive.name, controller);
})();
