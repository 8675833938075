import game from '../../../service/domain/game.js';
import keyup$ from '../../../service/rx/keyup$.js';
import { of, from } from 'rxjs';
import { filter, map, distinctUntilChanged, debounceTime, tap, switchMap, finalize } from 'rxjs/operators';

(function () {
  'use strict';

  controller.$inject = ['$scope', 'game', 'keyup$'];

  function controller($scope, _game, _keyup$) {
    $scope.result = {};
    $scope.total_count = 0;
    $scope.preloader = false;

    $scope.games = [];
    $scope.categories = [];
    $scope.providers = [];
    $scope.not_found = false;
    const MIN_LENGTH_FOR_REQUEST = 2;

    const options = {};

    this.$onInit = function () {
      if (this.gameCount) options.game_count = this.gameCount;
    };

    const getGames = (data) => {
      if (data && data.length >= MIN_LENGTH_FOR_REQUEST) {
        $scope.preloader = true;

        options.search = data;

        return from(_game.search(options));
      }

      return of(null);
    };

    const subscription = _keyup$
      .pipe(
        filter((e) => e.target.hasAttribute('batman-search-input')),
        map((val) => val.target.value),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => ($scope.not_found = false)),
        switchMap((data) => getGames(data).pipe(
          finalize(()=>{
            $scope.preloader = false
          })
        ))
      )
      .subscribe((data) => {
        $scope.games = [];
        $scope.categories = [];
        $scope.providers = [];
        if (data) {
          $scope.not_found = data.result.categories < 1 && data.result.games < 1 && data.result.providers < 1;

          $scope.result = data.result;
          $scope.total_count = data.total_categories_count + data.total_games_count + data.total_providers_count;

          $scope.games = data.result.games;
          $scope.categories = data.result.categories;
          $scope.providers = data.result.providers;
        }
      });

    this.$onDestroy = () => {
      subscription.unsubscribe();
    };
  }

  new Controller('search', controller, { gameCount: '<' });
})();

(function () {
  'use strict';

  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanSearch${i}`, 'search');
  });
})();
