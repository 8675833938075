import config from '../../service/configs/config.js';
import user$ from '../../service/rx/user$.js';
(function () {
  'use strict';

  function parseEventParams(category, action, label) {
    if (!category || !action) {
      return {};
    }
    if (label && typeof label === 'string' && ['click_button', 'no_label'].includes(label.toLowerCase())) {
      label = undefined;
    } else if (label && typeof label === 'object') {
      label = undefined;
    }

    return {
      Category: category,
      Action: action,
      Label: label,
    };
  }

  class Analytics {
    /**
     * @param {Function} engine - actual analytics sender
     * @param {string} label - label to create a namespace within a single application
     * */
    constructor(global, _user$) {
      if (!global) {
        return;
      }
      this.global = global;
      this.actionType = 'send';
      this.userId = null;

      _user$.subscribe((user) => {
        this.userId = user.status ? user.profile.account_id : null;
      });
    }

    send(...details) {
      if (!this.global) {
        return;
      }
      try {
        this.sendToDataLayer({
          ...parseEventParams(...details),
          event: 'autoEvent',
          userId: this.userId,
        });
      } catch (e) {
        if (window.Sentry) {
          window.Sentry.captureException(e);
        }
      }
    }

    sendToDataLayer(event) {
      if (!this.global) {
        return;
      }

      this.global.dataLayer?.push(event);
    }
  }

  AnalyticsFactory.$inject = ['config', '$window', 'user$'];

  /** @returns {Analytics} */
  function AnalyticsFactory(appConfig, globalObject, _user$) {
    // const isAvailable = typeof globalObject.ga === "function";
    const isConfigured = appConfig.googleAnalytics && appConfig.googleAnalytics.appId;

    if (!globalObject || !isConfigured) {
      return new Analytics();
    }

    if (!globalObject.dataLayer) {
      globalObject.dataLayer = [];
    }

    // const { appId, trackAs = "analyticsTracker" } = appConfig.googleAnalytics;
    return new Analytics(globalObject, _user$);
  }

  app.factory('analytics', AnalyticsFactory);
})();
