import system from "../../service/domain/system.js"
import { BehaviorSubject } from 'rxjs';

(function () {
  'use strict';

  controller.$inject = ['system'];

  function controller(_system) {
    const defaultState = {
      tab: 'deposit',
      step: 'payments',
      set: setValue,
      get showCryptoTab() {
        return _system.systemInfo.cashbox?.buy_crypto_enabled || false;
      },
    };

    const currentState$ = new BehaviorSubject(defaultState);
    const getValue = () => currentState$.getValue();
    const reset = () => currentState$.next(defaultState);

    function setValue(nextState) {
      const prevState = getValue();
      if (!nextState.tab || nextState.tab === prevState.tab) {
        currentState$.next({ ...prevState, ...nextState });
        return;
      }

      currentState$.next({ ...defaultState, ...nextState });
    }

    return {
      currentState$,
      setValue,
      getValue,
      reset,
    };
  }

  app.factory('cashboxState', controller);
})();
