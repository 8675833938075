import article from '../service/domain/article.js';
(function () {
  'use strict';

  const directive = { name: 'seoText' };

  controller.$inject = ['$location', 'article'];

  function controller($location, _article) {
    function compile() {
      return function ($scope, $element, $attrs, $ctrl, $transclude) {
        let block,
          listener = null;

        function clearName() {
          return $location.path().substring(4).replace(/\//g, '__') || 'home';
        }

        function checkIfNotEmpty(text) {
          const value = text.replace(/(?:^(?:&nbsp;)+)|(?:(?:&nbsp;)+$)/g, '').trim();
          if (value.length) {
            return true;
          }
          return false;
        }

        function getArticle() {
          if (block) {
            block.remove();
            block = null;
          }
          _article
            .item({ name: clearName() })
            .then((a) => {
              if (a.result.text && checkIfNotEmpty(a.result.text)) {
                $transclude($scope.$new(true), (clone, scope) => {
                  block = clone[0];
                  scope.article = a.result;
                  $element[0].parentNode.insertBefore(clone[0], $element[0].nextSibling);
                });
              }
            })
            .catch((e) => {})
            .finally(() => {
              if (!listener) {
                listener = $scope.$on('$locationChangeSuccess', () => {
                  getArticle();
                });
              }
            });
        }

        getArticle();
      };
    }

    return {
      restrict: 'A',
      transclude: 'element',
      compile,
    };
  }

  app.directive(directive.name, controller);
})();
