import popup from '../service/domain/popup.js';
import analytics from '../service/analytics/analytics.js';
import analyticsEvents from '../service/configs/analytic-events.js';
(function () {
  'use strict';

  controller.$inject = ['popup', 'analytics', 'analyticsEvents'];

  function controller(_popup, _analytics, _analyticsEvents) {
    function link(scope, element, attrs) {
      element.on('click', function () {
        _analytics.send(_analyticsEvents.popupClose, _popup.current.replace(/(\/|\.html$)/g, ''), 'Click_Button', {
          nonInteraction: true,
        });
        _popup.close();
      });
    }

    return {
      restrict: 'A',
      link: link,
    };
  }

  app.directive('popupClose', controller);
})();
