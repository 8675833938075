import user from '../domain/user.js'
import modal from '../domain/modal.js'
import state from '../domain/state.js'
import {bonusApi} from "../api/bonus-api.js";
import popup from '../domain/popup.js';

export const bonus = 'bonus';

class Bonus {
  static $inject = ['bonusApi', 'modal', 'user', 'state', 'popup'];

  constructor(_bonusApi, _modal, _user, _state, _popup) {
    this.user = _user;
    this.modal = _modal;
    this.state = _state;
    this.popup = _popup;
    this.bonusApi = _bonusApi;
  }

  list() {
    return this.bonusApi.getList();
  }

  runActivation(bonus, alias) {
    return this.bonusApi.activate(bonus.bonus_id)
      .then(() => {
        return this.resolveByBonusType(bonus.type, alias);
      })
  }

  activate(bonus, alias) {
    if (this.isLockedRetentionBonus(bonus)) {
      return this.modal.open('bonus-activation-rules', bonus);
    }
    if (this.user.activeBonus.bonus_id) {
      return this.modal.open('bonus-activation', bonus)
        .then(() => {
          return this.runActivation(bonus, alias)
        }).catch(e => e)
    }
    return this.runActivation(bonus, alias);
  }

  cancel(bonus) {
    if (bonus.status === 'active') {
      return this.modal.open('bonus-cancellation')
        .then(() => {
          return this.bonusApi.cancel(bonus.bonus_id);
        })
    }
    return this.bonusApi.cancel(bonus.bonus_id);
  }

  resolveByBonusType(bonusType, aliasOnActivate) {
    if (bonusType === 'freespin') {
      this.state.goto('realGame', { name: aliasOnActivate });
    }
    aliasOnActivate ? this.state.goto(aliasOnActivate) : this.popup.close();
  }

  isLockedRetentionBonus(bonus) {
    return bonus.is_retention_bonus && !bonus.retention_bonus_rules_fulfilled;
  }
}

app.service(bonus, Bonus)
