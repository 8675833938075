import account from '../../service/domain/account.js';
import helper from '../../service/helper.js';
import user from '../../service/domain/user.js';
import modal from '../../service/domain/modal.js';
(function () {
  'use strict';

  const component = { name: 'swedenProfileDepositLimitMonth' };
  const group = 'deposit';
  const type = 'month';

  controller.$inject = ['$scope', 'account', 'helper', 'user', 'modal'];

  function controller($scope, _account, _helper, _user, _modal) {
    $scope.editMode = false;
    $scope.preloader = false;
    $scope.formData = {
      amount: _user.profile.limits[group][type].amount,
    };
    $scope.limit = _user.profile.limits[group][type];

    $scope.cancel = () => {
      $scope.editMode = false;
      $scope.formData.amount = _user.profile.limits[group][type].amount;
    };

    $scope.submit = _helper.form.validator((form) => {
      if (checkLockedAmount()) {
        showModal();
        return;
      }
      setLimit();
    });

    const checkLockedAmount = () => {
      if ($scope.limit.locked_amount && $scope.formData.amount > $scope.limit.locked_amount) {
        return true;
      }
      return false;
    };

    const showModal = () => {
      const params = {
        locked_amount: $scope.limit.locked_amount,
        type,
      };
      _modal
        .open('deposit-limit-locked', params)
        .then(() => {})
        .catch(() => {});
    };

    const setLimit = () => {
      $scope.preloader = true;
      const o = {
        group,
        type,
        amount: parseInt($scope.formData.amount),
      };
      _account
        .limits(o)
        .then(
          (a) => {
            $scope.formData.amount = a.data.result.limits[group][type].amount;
            $scope.limit = a.data.result.limits[group][type];
            $scope.editMode = false;
          },
          (a) => {
            $scope.formData.amount = _user.profile.limits[group][type].amount;
            $scope.limit = _user.profile.limits[group][type];
          }
        )
        .finally(() => {
          $scope.preloader = false;
        });
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
