import http from '../../service/domain/http.js';
import api from '../../service/domain/api.js';
(function () {
  /** A fallback implementation if BeaconAPI isn't available*/
  class HttpFallback {
    constructor(http) {
      this.http = http;
    }

    /**
     * @param {string | Object} alias
     * @param {Object} payload */
    send(alias, payload) {
      return this.http.post(alias, payload, { meta: { alias } });
    }
  }

  /** BeaconAPI a lightweight api to log useful data.
   * It doesn't wait for a response so  api calls could be scheduled even if browser tab is closed;
   * https://developer.mozilla.org/en-US/docs/Web/API/Beacon_API
   * */
  class BeaconAPI {
    constructor(api, sendBeacon) {
      this.api = api;
      this.sendBeacon = sendBeacon;
    }

    /**
     * @param {string | Object} alias
     * @param {Object} payload */
    send(alias, payload) {
      const url = typeof alias === 'object' ? `${this.api.ask(alias.name)}${alias.param}` : this.api.ask(alias);
      const body = this.toFormData(payload);

      return this.sendBeacon(url, body);
    }

    /**
     * @param {Object} body
     * @returns {FormData}  */
    toFormData(body) {
      const pairs = Object.entries(body || {});

      return pairs.reduce((formData, [key, value]) => {
        formData.append(key, value);
        return formData;
      }, new FormData());
    }
  }

  beaconAPIController.$inject = ['http', '$window', 'api'];

  function beaconAPIController(http, globalWindow, api) {
    if (typeof globalWindow.navigator.sendBeacon === 'function') {
      return new BeaconAPI(api, (...args) => globalWindow.navigator.sendBeacon(...args));
    }

    return new HttpFallback(http);
  }

  app.factory('beaconAPI', beaconAPIController);
})();
