import account from '../../service/domain/account.js';
import helper from '../../service/helper.js';
import state from '../../service/domain/state.js';
(function () {
  'use strict';

  const component = { name: 'swedenInitialLimitForm' };

  controller.$inject = ['$scope', 'account', 'helper', 'state'];

  function controller($scope, _account, _helper, _state) {
    $scope.preloader = false;

    $scope.formData = {
      day: {
        deposit: { amount: 1000 },
        loss: { amount: 300 },
      },
      week: {
        deposit: { amount: 2000 },
        loss: { amount: 2000 },
      },
      month: {
        deposit: { amount: 10000 },
        loss: { amount: 10000 },
      },
    };

    $scope.formField = {
      day: {
        deposit: { min: 100, max: 5000 },
        //deposit: { min: 1, max: 700 },
        loss: { min: 1, max: 700 },
      },
      week: {
        deposit: { min: 100, max: 5000 },
        loss: { min: 1, max: 5000 },
      },
      month: {
        deposit: { min: 100, max: 20000 },
        loss: { min: 1, max: 20000 },
      },
    };

    $scope.defaultLimits = {
      day: {
        deposit: [1000, 3000, 5000],
        //deposit: [300, 500, 700],
        loss: [300, 500, 700],
      },
      week: {
        deposit: [1000, 3000, 5000],
        loss: [2000, 3000, 5000],
      },
      month: {
        deposit: [10000, 15000, 20000],
        loss: [10000, 15000, 20000],
      },
    };

    $scope.submit = _helper.form.validator((form) => {
      $scope.preloader = true;
      const params = [
        { type: 'day', group: 'deposit', amount: $scope.formData.day.deposit.amount },
        { type: 'week', group: 'deposit', amount: $scope.formData.week.deposit.amount },
        { type: 'month', group: 'deposit', amount: $scope.formData.month.deposit.amount },
        //                { type: 'day', group: 'losses', amount: $scope.formData.day.loss.amount },
        //                { type: 'week', group: 'losses', amount: $scope.formData.week.loss.amount },
        //                { type: 'month', group: 'losses', amount: $scope.formData.month.loss.amount }
      ];
      _account
        .multiLimits(params)
        .then((a) => {
          _state.goback();
        })
        .catch((e) => {})
        .finally(() => {
          $scope.preloader = false;
        });
    });
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
