import registration from "../../service/configs/registration.js"
import config from "../../service/configs/config.js"
// https://wiki.araxio.tech/display/FrontEndTeam/%5BINTERNAL%5D+Batman+registration
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators'
(function () {
  'use strict';

  const directive = { name: 'registrationAvatarsSlider' };

  controller.$inject = ['registration', 'config'];

  function controller(_registration, _config) {
    function link($scope, element, attrs) {
      const { config: currentSliderName, bind: sliderToBindName } = $scope.$eval(attrs[directive.name]);
      const currentSliderConfig = _config.slider[currentSliderName];
      const destroy$ = new Subject();
      let currentSlider;

      _registration.events$
        .pipe(
          takeUntil(destroy$),
          filter(({ action }) => action === _registration.eventTypes.initAvatarsSlider)
        )
        .subscribe(({ initialSlide }) => init(initialSlide));

      const init = (initialSlide) => {
        Object.assign(currentSliderConfig, { initialSlide });

        currentSlider = new Swiper(element[0], currentSliderConfig);

        _registration.registerSlider(currentSliderName, currentSlider);

        if (sliderToBindName) {
          _registration.bindSliders(currentSliderName, sliderToBindName);
        }

        if (!sliderToBindName) {
          currentSlider.on('slideChange', ({ activeIndex }) => $scope.setActiveAvatarByIndex(activeIndex));
        }
      };

      $scope.$onDestroy = () => {
        destroy$.next();
        destroy$.unsubscribe();

        _registration.unregisterSlider(currentSliderName);
      };
    }

    return {
      link,
      restrict: 'A',
    };
  }

  app.directive(directive.name, controller);
})();
