import holiday from "../service/periodicals/holiday.js"
(function () {
  'use strict';

  const directive = { name: 'showHolidayLink' };

  controller.$inject = ['holiday'];

  function controller(_holiday) {
    let holidayLink = {};
    let isInited = false;

    function link($scope, $element, $attrs, $ctrl, $transclude) {
      const linkExists = holidayLink.name ? true : false;

      if (isInited && !linkExists) {
        return;
      }

      const insertElement = () => {
        $scope.holidayLink = holidayLink;
        $transclude($scope, (clone) => {
          $element[0].parentNode.insertBefore(clone[0], $element[0].nextSibling);
        });
      };

      if (linkExists) {
        insertElement();
        return;
      }

      _holiday
        .getItem('halloween')
        .then(({ menu_item_name, menu_item_image }) => {
          isInited = true;
          if (!menu_item_name) {
            return;
          }
          holidayLink = {
            name: menu_item_name,
            imgPath: menu_item_image,
          };
          insertElement();
        })
        .catch(() => {})
        .finally(() => {});
    }

    return {
      restrict: 'A',
      link,
      transclude: 'element',
    };
  }

  app.directive(directive.name, controller);
})();
