import payment from '../../../service/domain/payment.js';
import modal from '../../../service/domain/modal.js';
import user from '../../../service/domain/user.js';
import helper from '../../../service/helper.js';
import bonus from '../../../service/domain/bonus.js';
import config from '../../../service/configs/config.js';
import system$ from '../../../service/rx/system$.js';
(function () {
  'use strict';

  const component = { name: 'lindaCashboxWithdrawForm' };

  controller.$inject = ['$rootScope', '$scope', 'payment', 'modal', 'user', 'helper', 'bonus', 'config', 'system$'];

  function controller($rootScope, $scope, _payment, _modal, _user, _helper, _bonus, _config, _system$) {
    $scope.preloader = false;
    $scope.system = {};
    $scope.formData = {
      amount: 0,
      details: [],
    };

    let wnd = {};

    this.$onChanges = () => {
      if (typeof this.system === 'object') {
        $scope.system = this.system;
        $scope.formData.paymethod = this.system.rules.system;
        $scope.formData.paysystem_id = this.system.id;
      }
    };

    function close(message) {
      if (message.data === 'close') {
        wnd.close();
        wnd = {};
      }

      if (message.data === 'open-chat') {
        wnd.close();
        wnd = {};
        _system$.next({ action: 'open chat' });
        //-alert( 'open chat from payment callback' );
      }
    }

    window.onmessage = close;

    function withdraw() {
      if (!_config.device.mobile) {
        $scope.preloader = true;
      }
      if (wnd.focus) {
        if (wnd.closed) {
          wnd = {};
        } else {
          wnd.focus();
          return;
        }
      }
      const w = 800,
        h = 500,
        l = (window.innerWidth - w) / 2,
        t = (window.innerHeight - h) / 2,
        checker = setInterval(() => {
          if (wnd.closed) {
            wnd = {};
            clearInterval(checker);
          }
        }, 100);
      wnd = _config.browser.webView
        ? window
        : window.open(
            `/preloader.html?login=${_user.profile.login}`,
            'withdrawalWindow',
            `height=${h},width=${w},left=${l},top=${t}`,
          );
      _payment
        .withdraw($scope.formData)
        .then((a) => {
          if (a.result.url.length) {
            wnd.document.location = a.result.url;
          }

          $rootScope.$emit('withdraw-new');
        })
        .catch((e) => {
          wnd.close();
        })
        .finally(() => {
          $scope.preloader = false;
        });
    }

    function cancelBonus() {
      return _bonus.cancel({ bonus_id: _user.activeBonus.bonus_id });
    }

    $scope.submit = _helper.form.validator((form) => {
      if (_user.profile.active_bonus > 0) {
        _modal.open('cashbox-withdraw').then(
          (a) => {
            cancelBonus().then(withdraw);
          },

          (a) => {},
        );
      } else {
        withdraw();
      }
    });
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
    bindings: {
      system: '<',
    },
  });
})();
