import zignsecPaynplay from '../../../service/zignsec/zignsec.paynplay.js';
import modal from '../../../service/domain/modal.js';
import trustly$ from '../../../service/rx/trustly$.js';
import state from '../../../service/domain/state.js';
(function () {
  'use strict';

  const directive = { name: 'lindaZimplerFrame' };
  const analyticsPlace = 'zimpler';

  factory.$inject = ['zimpler.paynplay', 'config', 'modal', 'trustly$', 'state'];

  function factory(_paynplay, _config, _modal, _trustly$, _state) {
    const link = (scope, element, attrs, $ctrl, $transclude) => {
      scope.preloader = true;

      let order_id,
        auth_request = false;

      function redirectToHomepage() {
        _state.goto('home');
      }

      function redirectToCashbox() {
        _state.goto('deposit');
      }

      window.onmessage = (m) => {
        switch (m.data) {
          case 'preloader:success':
            if (!auth_request) {
              auth_request = true;
              _paynplay
                .auth({ order_id })
                .then((a) => {
                  redirectToHomepage();
                  /* The very first successful response (after iframe was closed and 'success' event was sent)
                   * means that the user was identified and has been registered already.
                   * */
                })
                .catch((e) => {
                  if (e.messages[0].code === 5054) {
                    _modal
                      .open('trustly-registration', {
                        profile: e.messages[0].data.profile,
                        is_registration_bonuses_shown: e.messages[0].data.is_registration_bonuses_shown,
                        token: order_id,
                      })
                      .then(() => {
                        _config.jurisdiction === 'malta' ? redirectToCashbox() : redirectToHomepage();
                      })
                      .catch(() => {});
                  }
                })
                .finally(() => {
                  window.onmessage = null;
                });
            }
            break;

          case 'preloader:fail':
            _trustly$.next({ action: 'step', data: _paynplay.isDefaultJurisdiction ? -1 : 0 });
            break;

          default:
            break;
        }
      };

      _paynplay
        .init()
        .then((a) => {
          order_id = a.result.order_id;
          if (a.result.url) {
            $transclude(scope, (clone) => {
              clone[0].sandbox = 'allow-forms allow-scripts allow-same-origin allow-popups';
              clone[0].src = a.result.url;
              element[0].parentNode.insertBefore(clone[0], element[0].nextSibling);
            });
          }
        })
        .catch((e) => {})
        .finally(() => {
          scope.preloader = false;
        });
    };

    return {
      restrict: 'A',
      transclude: 'element',
      link,
    };
  }

  app.directive(directive.name, factory);
})();
