import paynplay from '../../../service/trustly/paynplay.js';
import modal from '../../../service/domain/modal.js';
import trustly$ from '../../../service/rx/trustly$.js';
import state from '../../../service/domain/state.js';
import { interval } from 'rxjs';
import { filter } from 'rxjs/operators';

(function () {
  'use strict';

  const directive = { name: 'lindaTrustlyBFrame' };

  factory.$inject = ['paynplay', 'modal', 'trustly$', 'state', '$timeout'];

  function factory(_paynplay, _modal, _trustly$, _state, $timeout) {
    const link = async (scope, element, attrs) => {
      scope.preloader = true;
      element[0].src = '/preloader.html';

      let order_id,
        auth_request = false;

      let interval$;

      let isCheckStatusRequestEnded = true;

      function success() {
        _state.goto('home');
      }

      async function auth(options) {
        auth_request = true;
        try {
          await _paynplay.auth(options);
          success();
        } catch (e) {
          switch (e.messages[0].code) {
            case 5054:
              $timeout(async () => {
                await _modal.open('trustly-registration', {
                  profile: e.messages[0].data.profile,
                  is_registration_bonuses_shown: e.messages[0].data.is_registration_bonuses_shown,
                  token: order_id,
                });
                success();
              }, 0);
              break;
            case 2106:
              $timeout(async () => {
                await _modal.open('trustly-b-create-login', {
                  profile: e.messages[0].data.profile,
                  token: order_id,
                });
                success();
              }, 0);
              break;
          }
        } finally {
          window.onmessage = null;
        }
      }

      async function checkStatus() {
        isCheckStatusRequestEnded = false;
        try {
          const statusData = await _paynplay.checkStatus({ order_id: _paynplay.data.order_id, paymethod: 'trustly' });

          switch (statusData.status) {
            case 'waiting_input':
              statusData.token = _paynplay.data.order_id;
              $timeout(async () => {
                await _modal.open('trustly-b-registration', statusData);
              }, 0);

            case 'verified':
              interval$.unsubscribe();
              break;
          }
        } catch {
          interval$.unsubscribe();
        } finally {
          isCheckStatusRequestEnded = true;
        }
      }

      function startIntervalForCheckingStatus() {
        interval$ = interval(1000)
          .pipe(filter(() => isCheckStatusRequestEnded))
          .subscribe(() => {
            checkStatus();
          });
      }

      window.onmessage = (m) => {
        if (m.origin === 'https://checkout.trustly.com') {
          const data = JSON.parse(m.data);

          if (data.method === 'OPEN_APP') {
            location.assign(data.appURL);
          }
        }

        switch (m.data) {
          case 'preloader:success':
            if (!auth_request) {
              auth({ order_id });
            }
            break;

          case 'preloader:fail':
            _trustly$.next({ action: 'step', data: 0 });
            break;

          default:
            break;
        }
      };

      try {
        const frameData = await _paynplay.init();
        order_id = frameData.result.order_id;
        element[0].sandbox = 'allow-forms allow-scripts allow-same-origin';
        element[0].src = frameData.result.url;
        startIntervalForCheckingStatus();
      } finally {
        scope.preloader = false;
      }
    };

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, factory);
})();
