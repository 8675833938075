import account from '../../service/domain/account.js';
import popup from '../../service/domain/popup.js';
(function () {
  'use strict';

  const component = { name: 'lindaRestorationNewPassForm' };

  controller.$inject = ['$scope', 'account', 'popup'];

  function controller($scope, _account, _popup) {
    $scope.preloader = false;
    $scope.formData = {
      token: $_GET['restore'],
    };

    $scope.submit = function (form) {
      if (form.$valid) {
        $scope.preloader = true;

        _account.restorationChangePassword($scope.formData).then(
          (answer) => {
            $scope.preloader = false;
            _popup.open({ name: 'login' });
          },
          (answer) => {
            $scope.preloader = false;
          }
        );
      } else {
        angular.forEach(form.$error, (type) => {
          angular.forEach(type, (field) => {
            field.$setTouched();
          });
        });
      }
    };
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
  });
})();
