import config from '../../service/configs/config.js';

(function () {
  'use strict';

  controller.$inject = ['config'];

  function controller(_config) {
    return {
      update(rules) {
        if (!rules || !rules.version) {
          return false;
        }
        var currentVersion = parseInt(_config.mobileConfig.version.replace(/\./g, '')) || 0;
        var newVersion = parseInt(rules.version.replace(/\./g, '')) || 0;
        var isUpdate = newVersion > currentVersion;
        return isUpdate;
      },
    };
  }

  app.factory('version', controller);
})();
