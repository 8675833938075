import {soundPlayer} from "../service/soundPlayer.js";

(function () {
  'use strict';

  const directive = { name: 'playTrackOnClick' };

  controller.$inject = ['soundPlayer'];

  function controller(soundPlayer) {
    function link(scope, element, attrs) {
      const track = scope.$eval(attrs[directive.name]);

      element.on('click', function () {
        soundPlayer.playTrack(track.name);
      });
    }

    return {
      restrict: 'A',
      link: link,
    };
  }

  app.directive(directive.name, controller);
})();
