import user from '../service/domain/user.js';
(function () {
  'use strict';

  const directive = {
    name: 'passwordValidate',
  };

  controller.$inject = ['user'];

  function controller(_user) {
    function link(scope, element, attrs, ctrl) {
      let login = '';
      let password = '';
      let watcher = function () {};
      const loginField = attrs[directive.name];

      if (loginField && loginField !== 'password-validate') {
        watcher = scope.$watch(loginField, (newVal) => {
          login = newVal;
          scope.formData.password = password;
          ctrl.$setValidity('login', login ? password !== login : true);
        });
      } else {
        login = _user.profile.login;
      }

      ctrl.$validators.login = (modelValue, viewValue) => {
        password = modelValue || viewValue;
        return !login || password?.toLowerCase() !== login?.toLowerCase();
      };

      scope.$on('$destroy', watcher);
    }

    return {
      restrict: 'A',
      link,
      require: 'ngModel',
    };
  }

  app.directive(directive.name, controller);
})();
