import http from '../../service/domain/http.js';
import history$ from '../../service/rx/history$.js';
import history from '../../service/domain/history.js';
(function () {
  'use strict';

  controller.$inject = ['$q', 'http', 'history$', 'history'];

  function controller($q, _http, _history$, _history) {
    return {
      maxDays: 1,

      updateMaxDays() {
        if (this.selectedTypes.length) {
          let filteredType = _history.filterData.type.filter((i) => this.selectedTypes.includes(i.name));

          this.maxDays = filteredType[0].maxDays;

          filteredType.forEach((i) => {
            if (0 < i.maxDays && i.maxDays < this.maxDays) {
              this.maxDays = i.maxDays;
            }
          });
        } else {
          this.maxDays = 1;
        }

        _history$.next({ action: 'maxDays' });
      },

      selectedTypes: [],

      updateTypes(typeName, actionFlag) {
        this.selectedTypes.toggle(typeName, actionFlag);

        this.updateMaxDays();

        _history$.next({ type: typeName });
      },
    };
  }

  app.factory('historyBalanceFlow', controller);
})();
