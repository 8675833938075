import popup from '../../service/domain/popup.js';
(function () {
  'use strict';

  const component = { name: 'lindaPopupBody' };

  controller.$inject = ['$scope', 'popup'];

  function controller($scope, _popup) {
    $scope.popup = _popup;
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
