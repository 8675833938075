import { localStorage } from '../service/localStorage.js';
import analyticsEvents from '../service/configs/analytic-events.js';
import analytics from '../service/analytics/analytics.js'

(function () {
  'use strict';

  controller.$inject = ['localStorage', 'analyticsEvents', '$window', 'analytics'];

  function controller(localStorage, analyticsEvents, $window, analytics) {
    let redirectMessage = 'swRedirectUrl'
    return {
      sendEventOnSuccessRedirect() {
        if($window.$_GET[redirectMessage]) {
          analytics.send(analyticsEvents.SwMirrorRedirect, 'success', $window.location.search)
          localStorage.remove(redirectMessage);
        }
      }
    };
  }

  app.factory('mirrorAnalytics', controller);
})();
