import user from '../../service/domain/user.js';
(function () {
  controller.$inject = ['user'];

  function controller(_user) {
    const o = {
      mask: Number,
      min: 0,
      max: 9999999,
      scale: 0,
      signed: false,
    };

    return {
      init($element, ngModel) {
        const element = $element[0];
        const inputMask = new IMask(element, o);

        inputMask.on('accept', () => {
          ngModel.$setViewValue(inputMask.typedValue);
        });

        ngModel.$formatters.push((modelValue) => {
          if (modelValue) {
            inputMask.typedValue = modelValue;
          }
          return inputMask.typedValue;
        });

        ngModel.$parsers.push(() => {
          return inputMask.typedValue;
        });

        return inputMask;
      },

      customValidators(scope, element, attrs, ngModel) {
        const params = {
          min: 100,
          max: 9999999,
        };

        if (typeof params !== 'undefined') {
          ngModel.$validators.amount = (modelValue, viewValue) => {
            const value = modelValue || viewValue;
            return value.toString().length ? value <= _user.profile.loyalty : true;
          };

          ngModel.$validators.min = (modelValue, viewValue) => {
            const value = modelValue || viewValue;
            return value.toString().length ? value >= params.min : true;
          };

          ngModel.$validators.max = (modelValue, viewValue) => {
            const value = modelValue || viewValue;
            return value.toString().length ? value <= params.max : true;
          };
        }
      },

      validator(instance) {
        return instance.masked.isComplete;
      },
    };
  }

  app.factory('lpAmount', controller);
})();
