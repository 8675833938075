import account from '../../service/domain/account.js';
import popup from '../../service/domain/popup.js';
import analytics from '../../service/analytics/analytics.js';
import analyticsEvents from '../../service/configs/analytic-events.js';
(function () {
  'use strict';

  const component = { name: 'lindaRestorationForm' };

  controller.$inject = ['$scope', 'account', 'popup', 'analytics', 'analyticsEvents'];

  function controller($scope, _account, _popup, _analytics, _analyticsEvents) {
    $scope.preloader = false;
    $scope.email = '';

    $scope.restore = function (form) {
      if (form.$valid) {
        $scope.preloader = true;
        _account.restore({ email: $scope.email }).then(
          (answer) => {
            _popup.close();
            _analytics.send(_analyticsEvents.recoveryFormSuccess, 'application_form');
            $scope.preloader = false;
          },
          (answer) => {
            _analytics.send(_analyticsEvents.recoveryFormFail, 'application_form');
            $scope.preloader = false;
          }
        );
      } else {
        angular.forEach(form.$error, (type) => {
          angular.forEach(type, (field) => {
            field.$setTouched();
          });
        });
      }
    };
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
  });
})();
