import collection from "../service/domain/collection.js"
(function () {
  'use strict';

  const directive = { name: 'getCollectionsToScope' };

  factory.$inject = ['collection'];

  function factory(_collection) {
    function link(scope, element, attrs) {
      const variable = attrs[directive.name] || 'collections';

      scope[variable] = {};

      _collection
        .list()
        .then((a) => {
          scope[variable].__proto__ = _collection.object;
        })
        .catch((e) => {});
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, factory);
})();
