import gameJackpot from '../../service/game-jackpot/game-jackpot.js';
(function () {
  'use strict';

  const component = { name: 'lindaGameJackpot' };

  controller.$inject = ['$scope', '$element', 'gameJackpot', '$timeout'];

  function controller($scope, $element, _gameJackpot, $timeout) {
    let subscription$;
    let gameId;

    this.$onInit = function () {
      const game = this.game;

      if (!game.jackpot) {
        $element.remove();
        return;
      }

      gameId = game.id;
      const jackpotIncrement = game.jackpot.amount_increment_per_second;
      $scope.jackpotAmount = game.jackpot.amount;
      $scope.jackpotCurrency = game.jackpot.currency;

      subscription$ = _gameJackpot
        .getStream(gameId, $scope.jackpotAmount, jackpotIncrement)
        .subscribe((jackpotAmount) => ($scope.jackpotAmount = jackpotAmount));
    };

    this.$onDestroy = () => {
      if (subscription$) {
        subscription$.unsubscribe();
      }
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
    bindings: {
      game: '<',
    },
  });
})();
