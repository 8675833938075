import modal from '../../../service/domain/modal.js';
import helper from '../../../service/helper.js';
import bank from '../../../service/domain/bank.js';
import contentBuffer from '../../../service/domain/content-buffer.js';
(function () {
  'use strict';

  const component = { name: 'lindaMailChangeModal' };

  controller.$inject = ['$scope', 'modal', 'helper', 'bank', 'contentBuffer'];

  function controller($scope, _modal, _helper, _bank, _contentBuffer) {
    $scope.preloader = false;
    $scope.formData = {
      email: null,
    };

    $scope.submit = _helper.form.validator((form) => {
      $scope.preloader = true;

      const o = _contentBuffer.read('modal');
      o.email = $scope.formData.email;

      _bank.auth(o).then(
        (answer) => {
          $scope.preloader = false;

          _modal.answer = true;
        },
        (answer) => {
          $scope.preloader = false;
        }
      );
    });
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
