import popup from '../../service/domain/popup.js';
import contentBuffer from '../../service/domain/content-buffer.js';
import user from '../../service/domain/user.js';
(function () {
  'use strict';

  const directive = { name: 'popupOpenTap' };

  controller.$inject = ['popup', 'contentBuffer', 'user'];

  function controller(_popup, _contentBuffer, _user) {
    function link(scope, element, attrs) {
      element.on('tap', () => {
        const attrsValue = scope.$eval(attrs[directive.name]);
        const config = typeof attrsValue === 'object' ? attrsValue : { name: attrs[directive.name] };

        if (config.private === true) {
          if (_user.status) {
            _popup.open(config);
          } else {
            _popup.open({
              name: 'login',
              cb() {
                _popup.open(config);
              },
            });
          }
        } else {
          _popup.open(config);
        }
      });
    }

    return {
      restrict: 'A',
      link: link,
    };
  }

  app.directive(directive.name, controller);
})();
