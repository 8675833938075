import favorites from "../../../service/domain/favorites.js"
(function () {
  'use strict';

  const baseClassName = 'batman-game-favourites';
  const emptyClassName = `${baseClassName}--empty`; // клас з'являється на блоці, коли немає елементів у collection

  controller.$inject = ['$scope', 'favorites', '$element'];

  function controller($scope, _favorites, $element) {
    $scope.collection = [];

    let count = 100;
    let subscription;

    function getCollection() {
      subscription?.unsubscribe();

      subscription = _favorites.collection(count).subscribe((games) => {
        $element[0].classList.toggle(emptyClassName, games.length === 0);
        $scope.collection = games;
        $scope.total_count = games.length;
      });
    }

    this.$onChanges = () => {
      if (this.count !== count) {
        count = this.count || 100;

        getCollection();
      }
    };

    this.$onDestroy = () => {
      subscription?.unsubscribe();
    };
  }

  new Controller('gameBoxFavourites', controller, { count: '<' });
})();

(function () {
  'use strict';

  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanGameBoxFavourites${i}`, 'gameBoxFavourites');
  });
})();
