import popup from '../service/domain/popup.js';
import user$ from '../service/rx/user$.js';
import user from '../service/domain/user.js';

(function () {
  'use strict';

  app.directive('hrefIfAuth', controller);

  controller.$inject = ['popup', 'user$', 'user', '$state'];

  function controller(_popup, _user$, _user, $state) {
    function parseStateRef(ref, current) {
      let preparsed = ref.match(/^\s*({[^}]*})\s*$/),
        parsed;
      if (preparsed) ref = current + '(' + preparsed[1] + ')';
      parsed = ref.replace(/\n/g, ' ').match(/^([^(]+?)\s*(\((.*)\))?$/);
      if (!parsed || parsed.length !== 4) throw new Error("Invalid state ref '" + ref + "'");
      return { state: parsed[1], paramExpr: parsed[3] || null };
    }

    function link(scope, element, attrs) {
      const variable = parseStateRef(attrs.hrefIfAuth);

      if (!_user.status) {
        element[0].href = $state.href(variable.state, scope.$eval(variable.paramExpr));
      } else {
        element[0].href = '';
      }

      _user$.subscribe(() => {
        if (!_user.status) {
          element[0].href = $state.href(variable.state, scope.$eval(variable.paramExpr));
        } else {
          element[0].href = '';
        }
      });
    }

    return {
      restrict: 'A',
      link: link,
    };
  }
})();
