import historyBalanceFlow from '../../../service/domain/historyBalanceFlow.js';
import history$ from '../../../service/rx/history$.js';
import { filter } from 'rxjs/operators';

(function () {
  'use strict';

  const directive = { name: 'lindaBalanceFlowPeriodFilterJquery' };

  controller.$inject = ['$compile', 'historyBalanceFlow', 'history$', '$timeout'];

  function controller($compile, _historyBalanceFlow, _history$, $timeout) {
    function link(scope, element, attrs) {
      const version = 'v11.1.13';
      const cdn = '/joxi';
      const url = `${cdn}/js/${version}/jquery.js`;
      const script = document.createElement('script');
      script.src = url;
      script.onload = () => {
        const changeRange = () => {
          let action = _historyBalanceFlow.maxDays !== 1 ? 'add' : 'remove';
          element[0].classList[action]('linda-has-range');
        };

        const options = scope.$eval(attrs[directive.name]) || {};
        const jqEl = $(element);

        const init = () => {
          jqEl
            .dateRangePicker(
              Object.assign(options, {
                showDropdowns: true,
                container: jqEl,
                singleDate: _historyBalanceFlow.maxDays === 1,
                singleMonth: _historyBalanceFlow.maxDays === 1,
                maxDays: _historyBalanceFlow.maxDays,
              }),
            )
            .bind('datepicker-change', (event, obj) => {
              $timeout(() => {
                scope.ngModel.from = obj.value.split('/')[0];
                scope.ngModel.to = obj.value.split('/')[1];
              });
            });
        };

        const destroy = () => {
          jqEl.data('dateRangePicker').clear();
          jqEl.data('dateRangePicker').destroy();
        };

        const subscribtion = _history$
          .pipe(filter((i) => i.action === 'maxDays'))
          .subscribe(() => {
            destroy();
            init();
            changeRange();
          });

        init();
        changeRange();

        scope.$on('$destroy', () => subscribtion.unsubscribe());
      };
      document.head.appendChild(script);
    }

    return {
      restrict: 'A',
      link,
      require: 'ngModel',
      scope: {
        ngModel: '=',
      },
    };
  }

  app.directive(directive.name, controller);
})();
