import user$ from "../../service/rx/user$.js"
import config from "../../service/configs/config.js"
import bonus$ from "../../service/rx/bonus$.js"
import system$ from "../../service/rx/system$.js"
import remembered from "../../service/domain/remembered.js"
(function () {
  'use strict';

  controller.$inject = ['user$', 'config', 'bonus$', 'system$', 'remembered'];

  function controller(_user$, _config, _bonus$, _system$, _remembered) {
    let model = {};

    Object.defineProperty(model, 'profile', {
      set(data) {
        _remembered.login = data.login;
        _remembered.accountId = data.account_id;
        _remembered.name = data.profile.name;
        _remembered.surname = data.profile.surname;

        if (data.profile && data.profile.avatar) {
          _remembered.avatar = data.profile.avatar;
        }
      },

      get() {
        return {
          login: _remembered.login,
          profile: {
            name: _remembered.name,
            surname: _remembered.surname,
            avatar: _remembered.avatar,
          },
        };
      },
    });

    return model;
  }

  app.factory('model', controller);
})();
