import additionalFields from "../../service/additionalFields.js"
(function () {
  'use strict';

  const directive = { name: 'registrationAdditionalFields' };

  controller.$inject = ['additionalFields'];

  function controller(_additionalFields) {
    function link($scope, element, attrs) {
      $scope.additionalFields = {};
      let fieldNames = $scope.$eval(attrs[directive.name]);
      let fieldDependencies = {};
      let initialFields = {};

      function getData() {
        return _additionalFields.getList(fieldNames).then(({ result: { additionalFields } }) => {
          additionalFields.forEach((field) => (initialFields[field.fieldName] = field.values));
        });
      }

      function formBaseData() {
        for (const [key, value] of Object.entries(initialFields)) {
          value.forEach(({ title, restriction }) => {
            if (!fieldDependencies.hasOwnProperty(key) && restriction) {
              fieldDependencies[key] = [...new Set(Object.keys(restriction))];
              return;
            }
            if (!restriction) {
              $scope.additionalFields[key] = value;
            }
          });
        }
      }

      function filterDependencies() {
        const filteredFields = {};
        for (const [key, value] of Object.entries(fieldDependencies)) {
          initialFields[key].forEach((item) => {
            value.forEach((data) => {
              if (!filteredFields.hasOwnProperty(item.restriction[data])) {
                filteredFields[item.restriction[data]] = [];
              }
              filteredFields[item.restriction[data]].push(item);
            });
          });
          initialFields[key] = filteredFields;
        }
      }

      function addWatchers() {
        for (const [key, value] of Object.entries(fieldDependencies)) {
          value.forEach((item) => {
            watchSelect(item, key);
          });
        }
      }

      function watchSelect(dependence, dependent) {
        $scope.$watch('formData.additionalFields.' + dependence, function (newVal) {
          $scope.additionalFields[dependent] = [];
          if (newVal) {
            $scope.additionalFields[dependent] = initialFields[dependent][newVal];
          }
        });
      }

      getData().then(() => {
        formBaseData();
        filterDependencies();
        addWatchers();
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
