import game from '../../service/domain/game.js';
import system$ from '../../service/rx/system$.js';

(function () {
  'use strict';

  const directive = { name: 'lindaToggleFavourite' };

  controller.$inject = ['game', 'system$'];

  function controller(_game, _system$) {
    const className = 'f_active';

    function link(scope, element, attrs) {
      let game;

      attrs.$observe(directive.name, (newName) => {
        game = newName;
        element.toggleClass(className, _game.favourite.check(game));
      });

      element.on('click', (e) => {
        element.toggleClass(className, _game.favourite.toggle(game));
        _system$.next({ action: 'renew favourite' });
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
