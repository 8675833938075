import contentBuffer from '../../service/domain/content-buffer.js';
(function () {
  'use strict';

  const component = { name: 'lindaTransitionalPopup' };

  cmpCtrl.$inject = ['$scope', 'contentBuffer'];

  function cmpCtrl($scope, _contentBuffer) {
    $scope.content = _contentBuffer.read('popup');
  }

  app.component(component.name, {
    controller: cmpCtrl,
    template: app.getTU(component.name),
  });
})();
