import promo from '../../../service/domain/promo.js';
(function () {
  'use strict';

  const component = { name: 'lindaWidgetPromo' };

  controller.$inject = ['$scope', 'promo'];

  function controller($scope, _promo) {
    this.$onChanges = function () {
      $scope.preloader = true;
      _promo.item({ name: this.name }).then(
        (answer) => {
          $scope.collection = answer.result;
          $scope.preloader = false;
        },
        (answer) => {
          $scope.preloader = false;
        }
      );
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
    bindings: {
      name: '<',
    },
  });
})();
