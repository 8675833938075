import http from "../service/domain/http.js"
(function () {
  controller.$inject = ['$q', 'http'];

  app.factory('additionalFields', controller);

  function controller($q, _http) {
    return {
      getList(options) {
        const defer = $q.defer(),
          alias = 'additionalFields',
          config = {
            params: options,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http
          .get(alias, config)
          .then(({ data }) => {
            defer.resolve(data);
          })
          .catch(({ data }) => {
            defer.reject(data);
          });

        return defer.promise;
      },
    };
  }
})();
