import promo from '../../service/domain/promo.js';
import system$ from '../../service/rx/system$.js';
import user from '../../service/domain/user.js';
import { filter } from 'rxjs/operators';
(function () {
  'use strict';

  // TODO: needs refactoring. urgent;

  const directive = { name: 'lindaPromoSlider' };

  factory.$inject = ['promo', 'system$', 'user'];

  function factory(_promo, _system$, _user) {
    function link($scope, $element, $attrs, $ctrl, $transclude) {
      function startSlickSlide($) {
        const slickBox = $($element[0].parentNode);

        const config = Object.assign(
          {
            initialSlide: 0,
          },
          $scope.$eval($attrs.sliderConfig),
        );

        let list = [];

        _promo
          .list()
          .then((a) => {
            list = a.result;

            for (let i = 0; i <= list.length - 1; i++) {
              $transclude($scope.$new(true), (clone, scope) => {
                scope.index = i;
                scope.slide = list[i];
                slickBox.append(clone[0]);
              });
            }

            slickBox.slick(config);

            const hashRegex = /^\#s-(\d+)$/;
            const pageIndex = hashRegex.exec(location.hash)[1];

            if (pageIndex) {
              slickBox.slick('slickGoTo', pageIndex, true);
            }

            slickBox.on('reInit', function () {
              $('.slick-current').prev().addClass('slick-previous');
              $('.slick-current').next().addClass('slick-next');
            });
            slickBox.on('beforeChange swipe', function () {
              $('.slick-slide').removeClass('slick-previous slick-next');
              $('.slick-current').prev().addClass('slick-previous');
              $('.slick-current').next().addClass('slick-next');
            });

            filter();
            const subscription = _system$
              .pipe(filter((m) => m.action === 'statusChanged'))
              .subscribe(() => {
                filter();
              });
            $scope.$on('$destroy', () => {
              subscription.unsubscribe();
            });
          })
          .catch((e) => {})
          .finally(() => {});

        const filter = () => {
          const o = _promo.object;
          slickBox.slick('slickUnfilter');
          slickBox.slick('slickFilter', (i) => o.hasOwnProperty(list[i].name));
        };
      }

      Promise.all([import('jquery'), import('slick-carousel')]).then(([$]) => {
        startSlickSlide($);
      });
    }
    return {
      restrict: 'A',
      transclude: 'element',
      link,
      scope: {},
    };
  }

  app.directive(directive.name, factory);
})();
