import api from '../../service/domain/api.js';
import http from '../../service/domain/http.js';
(function () {
  'use strict';

  app.factory('page', pageCtrl);

  pageCtrl.$inject = ['api', 'http', '$q'];

  function pageCtrl(_api, _http, $q) {
    return {
      item(options) {
        const defer = $q.defer(),
          alias = {
            name: 'pageItem',
            param: options.name,
          },
          config = {
            cache: true,
            meta: {
              cache: true,
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },
      collection() {},
    };
  }
})();
