import account from '../../service/domain/account.js';
import helper from '../../service/helper.js';
import user from '../../service/domain/user.js';
function swedenProfileLoginLimitController(group, type) {
  controller.$inject = ['$scope', 'account', 'helper', 'user'];

  function controller($scope, _account, _helper, _user) {
    $scope.editMode = false;
    $scope.preloader = false;

    $scope.formData = {
      amount: _user.profile.limits[group][type].amount,
    };

    $scope.limit = _user.profile.limits[group][type];

    $scope.cancel = () => {
      $scope.editMode = false;
      $scope.formData.amount = _user.profile.limits[group][type].amount;
    };

    $scope.cancelLimit = () => {
      $scope.preloader = true;

      _account
        .cancelLimit({ type, group })
        .then((a) => {
          $scope.limit = a.data.result.limits[group][type];
        })
        .catch(() => {})
        .finally(() => {
          $scope.preloader = false;
        });
    };

    $scope.submit = _helper.form.validator(setLimit);

    function setLimit() {
      $scope.preloader = true;
      const o = {
        group,
        type,
        amount: parseInt($scope.formData.amount),
      };
      _account
        .limits(o)
        .then(
          (a) => {
            $scope.formData.amount = a.data.result.limits[group][type].amount;
            $scope.limit = a.data.result.limits[group][type];
            $scope.editMode = false;
          },
          () => {
            $scope.formData.amount = _user.profile.limits[group][type].amount;
            $scope.limit = _user.profile.limits[group][type];
          }
        )
        .finally(() => {
          $scope.preloader = false;
        });
    }
  }

  return controller;
}
(function () {
  const component = { name: 'swedenProfileLoginLimitMonth' };
  const group = 'login_time';
  const type = 'month';

  app.component(component.name, {
    controller: swedenProfileLoginLimitController(group, type),
    template: app.getTU(component.name),
  });
})();
