import contentBuffer from '../service/domain/content-buffer.js';
import contentBuffer$ from '../service/rx/content-buffer$.js';
import { filter } from 'rxjs/operators';
(function () {
  'use strict';

  controller.$inject = ['contentBuffer', 'contentBuffer$'];

  function controller(_contentBuffer, _contentBuffer$) {
    function link(scope, element, attrs) {
      const variable = attrs.getPopupContentToScope || 'config';

      scope[variable] = _contentBuffer.read('popup');

      _contentBuffer$
        .pipe(filter((data) => data.name === 'popup'))
        .subscribe((data) => {
          scope[variable] = _contentBuffer.read('popup');
        });
    }

    return {
      restrict: 'A',
      link: link,
    };
  }

  app.directive('getPopupContentToScope', controller);
})();
