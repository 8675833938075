import system from '../../../service/domain/system.js';
import state from '../../../service/domain/state.js';
import zignsecPaynplay from '../../../service/zignsec/zignsec.paynplay.js';
import popup from '../../../service/domain/popup.js';
(function () {
  'use strict';

  const directive = { name: 'zimplerResumePlayButton' };

  factory.$inject = ['system', 'state', 'zimpler.paynplay', 'popup'];

  function factory(_system, _state, _zimpler_paynplay, _popup) {
    function link(scope, element, attrs) {
      element.on('click', (e) => {
        _zimpler_paynplay.data.flow = 'resume_play';
        _zimpler_paynplay.data.amount = 0;
        _zimpler_paynplay.data.step = 1;
        _state.goto('paynplay');
        _popup.close();
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, factory);
})();
