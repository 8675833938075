import renderTemplateObserver from "../../service/render/render-template-observer.js"
(function () {
  renderTemplateDirective.$inject = ['renderTemplateObserver'];

  function renderTemplateDirective(renderTemplateObserver) {
    function compile() {
      return ($scope, $element, $attrs, $controller, $transclude) => {
        const element = $element[0];

        const transcludeContent = () => {
          $transclude(($clone) => {
            element.parentNode.insertBefore($clone[0], element.nextSibling);
          });
        };

        const unsubscribe = renderTemplateObserver.subscribe(element, () => {
          transcludeContent();
          unsubscribe();
          element.remove();
        });

        $scope.$on('$destroy', unsubscribe);
      };
    }

    return {
      compile,
      restrict: 'E',
      transclude: true,
    };
  }

  app.directive('lindaRenderTemplate', renderTemplateDirective);
})();
