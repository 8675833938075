import banner from "../../service/domain/banner.js"
import config from "../../service/configs/config.js"
import state from "../../service/domain/state.js"
(function () {
  'use strict';

  controller.$inject = ['banner', 'config', 'state', 'analytics'];

  function controller(_banner, _config, _state, _analytics) {
    return {
      altenarFromConfig() {
        const places = [];
        const pages = new Map();

        window.addEventListener('message', ( { data: { fromSportBanner }} ) => {
          if(!fromSportBanner) return;

          const { alias, param, analytics } = fromSportBanner
          if(analytics) {
            _analytics.send(...analytics)
          }
          _state.goto(alias, param);

        });

        return new Promise((resolve, reject) => {
          let result = {};

          const configBanners = _config?.sportBanners
            ? _config.sportBanners
            : { bottomRight: { place: 'inframe_banner1' } };

          const createItem = (item) => {
            if (configBanners[item]?.options) {
              const {
                height = null,
                width = null,
                target = null,
                ratio = null,
                devices = null,
              } = configBanners[item].options;
              return Object.assign(
                {},
                height && { height },
                width && { width },
                target && { target },
                ratio && { ratio },
                devices && { devices }
              );
            }
            return;
          };

          const getAllBannersFromPlace = (places) => {
            const promises = places.map((el, index) => {
              if (configBanners[el]?.options && configBanners[el]?.options?.pages) {
                pages.set(index, [...configBanners[el].options.pages]);
              }
              return _banner.collection({ place: configBanners[el].place });
            });

            const dataFromPromises = Promise.all(promises)
              .then((data) => {
                data.forEach((bannerFromPlace, index) => {
                  if (bannerFromPlace.result.length) {
                    bannerFromPlace.result.forEach((banner) => {
                      if (pages.get(index)) {
                        pages.get(index).forEach((page) => {
                          result[page][places[index]].push({
                            url: banner.images.big,
                            link: banner.url,
                            ...createItem(places[index]),
                          });
                        });
                        return;
                      }
                      result[places[index]].push({
                        url: banner.images.big,
                        link: banner.url,
                        ...createItem(places[index]),
                      });
                    });
                  }
                });

                return result;
              })
              .catch((e) => {
                reject(e);
              });

            dataFromPromises.then(() => resolve(dataFromPromises));
          };

          const configureItemWithOptions = (item, el) => {
            if (item.options?.pages) {
              item.options.pages.forEach((page) => {
                if (!result?.[page]) result[page] = {};

                if (!result[page]?.[el]) result[page][el] = [];
              });
            }

            if (item?.iframe) {
              if (item.options?.pages) {
                for (let i = 0; i < item.options.pages.length; i++) {
                  result[item.options.pages[i]][el].push({
                    url: item.iframe + '?lang=' + _config.currentLang,
                    ...createItem(el),
                  });
                }
                return;
              }

              if (!result?.[el]) result[el] = [];

              result[el].push({
                url: item.iframe + '?lang=' + _config.currentLang,
                ...createItem(el),
              });
            }

            if (item?.place) {
              places.push(el);
            }
          };

          const configureItemWithoutOptions = (item, el) => {
            if (!result?.[el]) result[el] = [];

            if (item?.iframe) {
              result[el].push({ url: item.iframe + '?lang=' + _config.currentLang });
              return;
            }

            if (item?.place) {
              places.push(el);
            }
          };

          Object.keys(configBanners).forEach((el) => {
            if (configBanners[el]?.options) {
              configureItemWithOptions(configBanners[el], el);
              return;
            }
            configureItemWithoutOptions(configBanners[el], el);
          });

          getAllBannersFromPlace(places);
        });
      },
    };
  }

  app.factory('sportBannerConfig', controller);
})();
