import abandonedRegistration from '../service/domain/abandonedRegistration.js';
(function () {
  'use strict';

  // this directive used for watching all inputs in the form and send formData to backabd after blur event

  const directive = { name: 'abandonedRegistration' };

  controller.$inject = ['abandonedRegistration'];

  function controller(_abandonedRegistration) {
    function link(scope, element, attrs) {
      // remove submit, button, checkbox, radio types from HTMLFormControlsCollection of current form
      const elementsForListen = [].filter.call(
        element[0].elements,
        (item) => !['submit', 'button', 'checkbox', 'radio'].includes(item.type)
      );

      function sendData() {
        _abandonedRegistration.setData(scope.formData);
      }

      for (const item of elementsForListen) {
        item.addEventListener('blur', sendData, false);
      }

      scope.$on('$destroy', () => {
        for (const item of elementsForListen) {
          item.removeEventListener('blur', sendData, false);
        }
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
