import paysystem from '../service/domain/paysystem.js';
import payment from '../service/domain/payment.js';
import popup from '../service/domain/popup.js';
import contentBuffer from '../service/domain/content-buffer.js';
(function () {
  'use strict';

  app.directive('checkLastDeposit', controller);

  controller.$inject = ['paysystem', 'payment', 'popup', 'contentBuffer'];

  function controller(_paysystem, _payment, _popup, _contentBuffer) {
    let lastDepFlag = false;

    function link(scope, element, attrs) {
      if (_contentBuffer.read('popup').paySystem) {
        return;
      }

      const tabNameFromAttr = scope.$eval(attrs.checkLastDeposit) || 'deposit';

      scope.preloader = true;
      scope.deposit = null;

      const depositList = _paysystem.collection({ type: 'deposit' }).then((depositList) => depositList.result);

      const depositLast = _payment.lastDep().then((depositLast) => depositLast.result);

      Promise.all([depositList, depositLast])
        .then((depositArray) => {
          depositArray[0].find((item) => {
            const paymentSystem = depositArray[1].payment_system || depositArray[1].paysystem;
            if (item.name === paymentSystem.name) {
              scope.deposit = Object.assign({}, { ...item }, { amount: depositArray[1].amount });
              if (!lastDepFlag) {
                _popup.open({
                  name: 'cashbox',
                  content: { tab: `${tabNameFromAttr}`, paySystem: { ...scope.deposit } },
                });
                lastDepFlag = true;
              }
            }
          });
        })
        .catch(() => {})
        .finally(() => {
          scope.preloader = false;
        });

      scope.$on('$destroy', () => {
        lastDepFlag = false;
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }
})();
