import '../analytics/p161.js';
import '../domain/popup.js';
import '../domain/modal.js';
import '../domain/state.js';
import '../configs/config.js';
import '../rx/system$.js';
import '../domain/account.js';

(function () {
  'use strict';

  app.factory('notificationsEvents', controller);

  controller.$inject = ['p161', 'popup', 'state', 'modal', 'config', 'system$', 'account'];

  function controller(_p161, _popup, _state, _modal, _config, _system$, _account) {
    const NOTIFICATION_TYPE_DEFAULT = 'default';
    const NOTIFICATION_TYPE_ERROR = 'error';

    return {
      // the event arrives after the first deposit is made (FED-403)
      20000001: {
        type: NOTIFICATION_TYPE_DEFAULT,
        callback: () => {
          _p161.track('firstDeposit');
        },
      },
      // the event arrives after the first deposit is made (FED-403)
      5097: {
        type: NOTIFICATION_TYPE_DEFAULT,
        callback: () => {
          _p161.track('firstDeposit');
        },
      },
      // migration to other project (B2C-4056)
      6200: {
        type: NOTIFICATION_TYPE_DEFAULT,
        callback: (data) => {
          _popup.close();
          _state.goto('migrationPageToOtherProject', { url: data.data.url });
        },
      },
      // account frozen for sweden
      2808: {
        type: NOTIFICATION_TYPE_DEFAULT,
        callback: () => {
          _modal.open('sw-account-frozen');
        },
      },
      // session length sw license
      5031: {
        type: NOTIFICATION_TYPE_DEFAULT,
        callback: (data) => {
          _modal.open('session-length', data);
        },
      },
      // user will see a modal with a checkbox indicating acceptance of the new Rules and Conditions ( BAC-2010 )
      5042: {
        type: NOTIFICATION_TYPE_DEFAULT,
        callback: (data) => {
          _popup.close();
          _modal
            .open('terms-changed', data)
            .then(() => {})
            .catch(() => {
              _state.goto('home');
            });
        },
      },
      // when the session has timed out
      117: {
        type: NOTIFICATION_TYPE_DEFAULT,
        callback: (data) => {
          if (_config.jurisdiction === 'germany') {
            _modal.open('de-session-length', data);
          } else {
            _modal.open('mga-session-length', data);
          }
        },
      },
      // mga. session limit exceeded
      5111: {
        type: NOTIFICATION_TYPE_DEFAULT,
        callback: (data) => {
          _modal.open('mga-exceeded-session-limit', data);
        },
      },
      // mga. deposit limit exceeded
      5110: {
        type: NOTIFICATION_TYPE_DEFAULT,
        callback: (data) => {
          _modal.open('mga-exceeded-deposit-limit', data);
        },
      },
      // Wager limit exceeded
      2320: {
        type: NOTIFICATION_TYPE_DEFAULT,
        callback: (data) => {
          _modal.open('mga-exceeded-wager-limit', data);
        },
      },
      // Losses limit exceeded
      2321: {
        type: NOTIFICATION_TYPE_DEFAULT,
        callback: (data) => {
          _modal.open('mga-exceeded-loss-limit', data);
        },
      },
      // Losses limit exceeded for Canada(only)
      5120: {
        type: NOTIFICATION_TYPE_DEFAULT,
        callback: (data) => {
          _modal.open('mga-exceeded-loss-limit', data);
        },
      },
      // When Player from Germany will log in at zulabet.com while their account is suspended (frozen) before migration. (B2C-2716)
      2224: {
        type: NOTIFICATION_TYPE_DEFAULT,
        callback: (data) => {
          _modal.open('de-suspended-players', data);
        },
      },
      // cool off
      5114: {
        type: NOTIFICATION_TYPE_DEFAULT,
        callback: () => {
          _popup.open({ name: 'cool-off' });
        },
      },
      // user missed data popup for India market
      13000013: {
        type: NOTIFICATION_TYPE_DEFAULT,
        callback: (data) => {
          const content = data.data;
          _popup.open({ name: 'in-missed-data', content });
        },
      },
      // When user try login her get modal because make self-exclusion from https://www.spelpaus.se/ service.
      2802: {
        type: NOTIFICATION_TYPE_DEFAULT,
        callback: () => {
          _modal.open('spelpaus-self-exclusion').then(() => {
            _state.goto('home');
          });
        },
      },
      // Show bonus activation rules modal and do not show notification
      6322: {
        type: NOTIFICATION_TYPE_DEFAULT,
        callback: () => {
          _popup.close();
        },
      },
      // the first login, a modal that informs the user that he can set dep limits (MGA)
      1332: {
        type: NOTIFICATION_TYPE_DEFAULT,
        callback: (data) => {
          if (_modal.current?.includes('registration')) {
            _modal.answer = true;
          }
          _modal
            .open('error', data)
            .then(() => {
              _popup.close();
              _state.goto('home');
              _state.goto('deposit');
            })
            .catch(() => {
              _popup.close();
              _state.goto('responsibleGaming');
            });
        },
      },
      // Your account has been migrated to the new project. (B2C-614,B2C-647 )
      1334: {
        type: NOTIFICATION_TYPE_ERROR,
        resolveCallback: () => {
          _popup.close();
        },
      },
      // logout
      5073: {
        type: NOTIFICATION_TYPE_ERROR,
        resolveCallback: () => {
          _system$.next({ action: 'forced logout' });
        },
      },
      // set deposit limit (redirect to deposit init page)
      2805: {
        type: NOTIFICATION_TYPE_ERROR,
        resolveCallback: () => {
          _state.goto('initialLimitPage');
        },
      },
      // set deposit limit before deposit (initial limits)
      5079: {
        type: NOTIFICATION_TYPE_ERROR,
        resolveCallback: () => {
          _state.goto('initialLimitPage');
        },
      },
      // mga. user self excluded
      5112: {
        type: NOTIFICATION_TYPE_ERROR,
        resolveCallback: () => {
          _state.goto('home');
        },
      },
      // the user tries to make a withdrawal, but the email is not confirmed (MGA)
      1333: {
        type: NOTIFICATION_TYPE_ERROR,
        rejectCallback: () => {
          _account
            .activationCode({})
            .then(() => {})
            .catch(() => {});
        },
      },
      // reality check
      1350: {
        type: NOTIFICATION_TYPE_ERROR,
        rejectCallback: () => {
          _account.logout().then(() => {
            _state.goto('home');
          });
        },
      },
      // temporarily blocked (period specified)
      2205: {
        type: NOTIFICATION_TYPE_ERROR,
      },
      // permanently selfexcluded
      2281: {
        type: NOTIFICATION_TYPE_ERROR,
      },
      // blocked by admin
      2202: {
        type: NOTIFICATION_TYPE_ERROR,
      },
      // closed account
      2092: {
        type: NOTIFICATION_TYPE_ERROR,
      },
      // due to incorrect password entry
      2203: {
        type: NOTIFICATION_TYPE_ERROR,
      },
      // Bet limit exceeded
      1320: {
        type: NOTIFICATION_TYPE_ERROR,
      },
      // Loss limit exceeded
      1321: {
        type: NOTIFICATION_TYPE_ERROR,
      },
      // registration result for malta
      1330: {
        type: NOTIFICATION_TYPE_ERROR,
      },
      // Deposit limit exceeded
      2220: {
        type: NOTIFICATION_TYPE_ERROR,
      },
      // a minute before
      5030: {
        type: NOTIFICATION_TYPE_ERROR,
      },
      // mga. user self excluded permanently
      5109: {
        type: NOTIFICATION_TYPE_ERROR,
      },
      // Business request - to implement a pop-up notification, when withdrawals go to pending - B2C-1833
      5303: {
        type: NOTIFICATION_TYPE_ERROR,
      },
      // for the German project, when the user logs in later than 24 hours have passed since the last session (ger-134)
      5118: {
        type: NOTIFICATION_TYPE_ERROR,
      },
      // if the user tries to log in after making a 24-hour self-exclusion using the panic button (FED-2065)
      5113: {
        type: NOTIFICATION_TYPE_ERROR,
      },
      5555: {
        type: NOTIFICATION_TYPE_ERROR,
      },
    };
  }
})();
