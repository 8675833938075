import subscription from '../../../service/domain/subscription.js';
(function () {
  'use strict';

  const component = { name: 'lindaProfileUnsubscribe' };

  controller.$inject = ['$scope', 'subscription', '$timeout'];

  function controller($scope, _subscription, $timeout) {
    $scope.preloader = false;
    $scope.collection = [];
    $scope.formData = {};
    $scope.uncheck = {
      value: false,
      changed: false,
    };

    $scope.$watch(
      'formData',
      (newVal, oldVal) => {
        const form = Object.values(newVal).indexOf(true);

        if (form >= -1 && $scope.uncheck.value === true) $scope.uncheck.value = false;
      },
      true
    );

    $scope.$watch('uncheck.value', (newVal, oldVal) => {
      if (newVal) Object.keys($scope.formData).forEach((item) => ($scope.formData[item] = false));
    });

    $scope.submit = (form) => {
      if (form.$valid) {
        const result = Object.keys($scope.formData).filter((item) => $scope.formData[item] === false);
      } else {
        angular.forEach(form.$error, (type) => {
          angular.forEach(type, (field) => {
            field.$setTouched();
          });
        });
      }
    };

    function getCollection() {
      $scope.preloader = true;
      _subscription.collection().then(
        (answer) => {},
        (answer) => {
          answer = {
            result: [
              { alias: 'test1', checked: true },
              { alias: 'test2', checked: true },
              { alias: 'test3', checked: true },
              { alias: 'test4', checked: true },
              { alias: 'test5', checked: true },
              { alias: 'test6', checked: true },
            ],
          };

          answer.result.forEach((item) => {
            $scope.formData[item.alias] = item.checked;
          });

          $scope.collection = answer.result;
          $scope.preloader = false;
        },
        (answer) => {
          $scope.preloader = false;
        }
      );
    }

    getCollection();
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
