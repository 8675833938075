import http from '../../service/domain/http.js';
(function () {
  'use strict';

  controller.$inject = ['http', '$q'];

  function controller(_http, $q) {
    return {
      collection(options) {
        let defer = $q.defer(),
          alias = 'jackpotLatest',
          config = {
            params: options,
            cache: true,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          function (answer) {
            defer.resolve(answer.data);
          },
          function (answer) {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },
    };
  }

  app.factory('jackpot', controller);
})();
