import user from "../../../service/domain/user.js"
import user$ from "../../../service/rx/user$.js"
import { Subject } from 'rxjs';
import { takeUntil, distinctUntilChanged, map } from 'rxjs/operators';

(function () {
  'use strict';

  controller.$inject = ['$scope', '$interval', 'user', 'user$'];

  function controller($scope, $interval, _user, _user$) {
    const destroy$ = new Subject();
    let sessionStart = _user.profile.sessions_info.current_ts * 1000;
    $scope.timeOfSession = 0;

    calculateTimeOfSession();
    const interval = $interval(() => {
      calculateTimeOfSession();
    }, 1000);

    function calculateTimeOfSession() {
      const dateNow = Date.now();
      const diffUserAndServerTime = _user.profile.diffUserAndServerTime;
      const result = Math.floor((dateNow - sessionStart - diffUserAndServerTime) / 1000);
      $scope.timeOfSession = result > 0 ? result : 0;
    }

    _user$
      .pipe(
        takeUntil(destroy$),
        map(({ profile }) => profile.sessions_info?.current_ts),
        distinctUntilChanged()
      )
      .subscribe((current_ts) => {
        if (current_ts) {
          sessionStart = current_ts * 1000;
          $scope.timeOfSession = 0;
          calculateTimeOfSession();
        }
      });

    this.$onDestroy = () => {
      $interval.cancel(interval);
      destroy$.next();
      destroy$.unsubscribe();
    };
  }

  new Controller('sessionTime', controller);
})();

(function () {
  'use strict';

  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanSessionTime${i}`, 'sessionTime');
  });
})();
