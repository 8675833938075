import collection from "../service/domain/collection.js"
(function () {
  'use strict';

  const directive = { name: 'getCollectionGamehallToScope' };

  factory.$inject = ['collection'];

  function factory(_collection) {
    function link(scope) {
      scope.collectionGameHall = [];

      _collection
        .collectionHall()
        .then((a) => {
          scope.collectionGameHall = a.result;
          scope.GameHallByAlias = a.result.toObject('alias');
        })
        .catch((e) => {});
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, factory);
})();
