import bonus from "../service/domain/bonus.js"
import user from "../service/domain/user.js"
import modal from "../service/domain/modal.js"
import state from "../service/domain/state.js"
import account from "../service/domain/account.js"
import popup from "../service/domain/popup.js"
import game from "../service/domain/game.js"

(function () {
  'use strict';

  controller.$inject = ['bonus', 'user', 'modal', 'state', '$state', 'account', 'popup', 'game'];

  function controller(_bonus, _user, _modal, _state, $state, _account, _popup, _game) {
    function activate(bonus_id) {
      this.preloader = true;

      _bonus.activate({ bonus_id }).then(
        (a) => {
          if (this.bonus.type === 'freespin') {
            const location = _state.get('realGame');
            $state.go(location.name, { name: this.bonus.game.alias });
          }
          //todo зробити розумніше
          _popup.close();
          this.preloader = false;
        },
        (a) => {
          this.preloader = false;
        }
      );
    }

    function cancel(bonus_id) {
      this.preloader = true;
      _bonus.cancel({ bonus_id }).then(
        (a) => {
          this.preloader = false;
        },
        (a) => {
          this.preloader = false;
        }
      );
    }

    const bonus = {
      activate(id) {
        if (_user.activeBonus.bonus_id) {
          _modal.open('bonus-activation').then(
            (a) => {
              activate.apply(this, [id]);
            },
            (a) => {}
          );
        } else {
          activate.apply(this, [id]);
        }
      },

      cancel(id) {
        if (typeof this.bonus === 'object' && this.bonus.status === 'active') {
          _modal.open('bonus-cancellation').then(
            (a) => {
              cancel.apply(this, [id]);
            },
            (a) => {}
          );
        } else {
          cancel.apply(this, [id]);
        }
      },
    };

    const freespin = {
      activate: bonus.activate,
      cancel: bonus.cancel,
    };

    const sportbonus = {
      activate: bonus.activate,
      cancel: bonus.cancel,
    };

    const account = {
      refresh() {
        this.preloader = true;
        _account.info({ nocache: true }).then(
          (a) => {
            this.preloader = false;
          },
          (a) => {
            this.preloader = false;
          }
        );
      },
    };

    const form = {
      setTouched(form) {
        angular.forEach(form.$error, (type) => {
          angular.forEach(type, (field) => {
            field.$setTouched();
          });
        });
      },

      validator(cb) {
        return function (frm) {
          if (frm.$valid) {
            if (typeof cb === 'function') cb(frm);
          } else {
            form.setTouched(frm);
          }
        };
      },
    };

    const game = {
      collection(o, cb) {
        this.preloader = true;
        _game.collection(o).then(
          (answer) => {
            this.collection = answer.result;
            if (typeof cb === 'function') cb(answer.result);
            this.preloader = false;
          },
          (answer) => {
            this.preloader = false;
          }
        );
      },
    };

    const dom = {
      isElementInViewport(el) {
        const rect = el.getBoundingClientRect();
        return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
      },

      addScript(url, onload) {
        if (url.length) {
          const element = document.createElement('script');
          element.src = url;
          element.async = true;
          if (typeof onload === 'function') {
            element.onload = onload;
          }
          document.head.appendChild(element);
        }
      },
    };

    return {
      bonus,
      freespin,
      sportbonus,
      account,
      form,
      game,
      dom,
    };
  }

  app.factory('helper', controller);
})();
