import { holidayApiService } from './holidayApi.js';
import { system$ } from '../../service/rx/system$.js';
import user from '../../service/domain/user.js';
import popup from '../../service/domain/popup.js';
import { filter } from 'rxjs/operators';
export const holidayPromo = 'holidayPromo';

class HolidayPromo {

  static $inject = ['holidayApiService', 'system$', 'user', '$timeout', 'popup'];
  constructor(holidayApiService, system$, user, $timeout, popup) {
    this.holidayApiService = holidayApiService;
    this.system$ = system$;
    this.user = user;
    this.$timeout = $timeout;
    this.popup = popup;
  }

  promos = [];
  inProgress = [];

  init() {
    this.getPromoList().then(() => {
      this.initWelcomePopup();
    });
  }

  convertToIsoString(date) {
    if (!date) return;
    const yourDate = new Date(date);
    return yourDate.toISOString().split('T')[0];
  }

  triggerPopup(previousLogin, currentDate) {
    if (previousLogin === currentDate) {
      return;
    }

    this.$timeout(() => {
      this.popup.open({ name: 'holiday-welcome', content: { name: this.promos } });
    }, 1000);
  }

  getPromoList() {
    return new Promise((resolve) => {
      if (this.promos.length) {
        resolve(this);
        return;
      }
      this.holidayApiService.list().then((data) => {
        if (!data.result.length) {
          return this;
        }
        this.promos = [...data.result];
        data.result.forEach((promo) => {
          if (promo.status === 'progress') {
            this.inProgress.push(promo.template);
          }
          this[promo.template] = {
            template: promo.template,
            name: promo.menu_item_name,
            img: promo.menu_item_image,
          };
        });
        resolve(this);
      });
    });
  }

  initWelcomePopup() {
    this.system$
      .pipe(filter((message) => message.action === 'login'))
      .subscribe(() => {
        const currentDate = +new Date();
        const previousLoginDate = this.user.profile.sessions_info.previous_ts * 1000 || null;
        if (this.inProgress.length) {
          this.triggerPopup(this.convertToIsoString(previousLoginDate), this.convertToIsoString(currentDate));
        }
      });
  }
}

app.factory(holidayPromo, HolidayPromo);
