import event from "../service/event.js"
(function () {
  'use strict';

  const directive = { name: 'addClassOnEvent' };

  controller.$inject = ['event'];

  function controller(_event) {
    function link($scope, $element, $attrs) {
      const { event, selector, delay, class: classNames, stopPropagation } = $scope.$eval($attrs[directive.name]);

      $element.on(event || 'click', (e) => {
        if (stopPropagation === true) {
          e.stopPropagation();
        }

        const targetElements = selector ? document.querySelectorAll(selector) : [$element[0]];
        const classNamesAsArray = classNames?.split(' ') || [];

        targetElements.forEach((targetElement) => {
          targetElement.classList.add(...classNamesAsArray);
        });

        if (delay) {
          setTimeout(() => {
            targetElements.forEach((targetElement) => {
              targetElement.classList.remove(...classNamesAsArray);
            });
          }, delay);
        }
      });

      _event.create(event, $element[0]);
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
