import game from '../../service/domain/game.js';
(function () {
  'use strict';

  const component = { name: 'lindaGameBoxAddOns' };

  controller.$inject = ['$scope', 'game'];

  function controller($scope, _game) {
    $scope.collection = [];
    $scope.preloader = false;
    $scope.content = {};
    $scope.category = null;
    $scope.provider = null;

    this.$onInit = function () {
      const config = {};

      $scope.content = this.content;
      $scope.category = this.category;
      $scope.provider = this.provider;

      config.category = this.category;
      config.brand = this.provider;
      config.tournament = this.tournament;

      config.count = this.count > 24 ? 24 : this.count;
      config.out = this.fields;

      config.random = this.random;

      $scope.preloader = true;
      _game.collection(config).then(
        (answer) => {
          $scope.collection = answer.result;
        },
        (answer) => {
          $scope.preloader = false;
        }
      );
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
    bindings: {
      category: '<',
      count: '<',
      content: '<',
      provider: '<',
      fields: '<',
      tournament: '<',
      random: '<',
    },
  });
})();
