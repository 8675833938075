import http from '../../service/domain/http.js';
import user from '../../service/domain/user.js';
import { Subject } from 'rxjs';

(function () {
  'use strict';

  controller.$inject = ['http', '$q', 'user'];

  function controller(_http, $q, _user) {
    const betslipUpdate$ = new Subject();

    return {
      launch(options) {
        const defer = $q.defer(),
          alias = 'sportLaunch',
          config = {
            params: options,
            cache: false,
            meta: {
              type: 'api',
              alias,
            },
          };



        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },

      feed(options) {
        const defer = $q.defer(),
          alias = 'sportFeed',
          config = {
            params: options,
            cache: false,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },

      check(options) {
        const defer = $q.defer(),
          alias = 'sportCheck',
          config = {
            cache: false,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.post(alias, options, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },

      betslipUpdate$,
    };
  }

  app.factory('sport', controller);
})();
