import user$ from '../service/rx/user$.js';
import user from '../service/domain/user.js';
(function () {
  'use strict';

  controller.$inject = ['user$', 'user'];

  function controller(_user$, _user) {
    function link(scope, element, attrs) {
      let className = attrs.addClassOnLogin;

      function setClass(status) {
        element.removeClass(className);

        if (status) {
          element.addClass(className);
        }
      }

      setClass(_user.status);

      _user$.subscribe((data) => {
        setClass(_user.status);
      });
    }

    return {
      restrict: 'A',
      link: link,
    };
  }

  app.directive('addClassOnLogin', controller);
})();
