import user from '../service/domain/user.js';
import config from '../service/configs/config.js';
import state from '../service/domain/state.js';
import notification$ from '../service/rx/notification$.js';
import system$ from '../service/rx/system$.js';
import popup from '../service/domain/popup.js';
import pgwApi from '../service/api/pgw-api.js';
import ws from '../service/domain/ws.js';
import wsEvents from '../service/configs/ws-events.js';
import ws$ from '../service/rx/ws$.js';
import { ReplaySubject, combineLatest } from 'rxjs';
import { filter, takeUntil, take } from 'rxjs/operators';

(function () {
  controller.$inject = [
    'user',
    'config',
    'state',
    'notification$',
    'system$',
    'popup',
    'pgwApi',
    'ws',
    'ws$',
    'wsEvents',
    '$translate',
    '$window',
  ];

  app.factory('pgw', controller);

  function controller(
    _user,
    _config,
    _state,
    _notification$,
    _system$,
    _popup,
    _pgwApi,
    _ws,
    _ws$,
    _wsEvents,
    $translate,
    $window,
  ) {
    const { langReplacements } = $window.$_DICT.pgw;
    const pgw = $window.pgw_widget;

    const login$ = _system$.pipe(filter((message) => message.action === 'login'));
    const logout$ = _system$.pipe(filter((message) => message.action === 'logout'));
    const balanceUpdate$ = _ws$.pipe(filter(({ event }) => event === _wsEvents.balanceUpdate));
    const pgwInitialization$ = new ReplaySubject(1);
    const pgwLoad$ = new ReplaySubject(false);
    let pgwInitSubscribtion;

    const callbacks = {
      getAuthToken() {
        return _pgwApi
          .getData()
          .then(({ result }) => result.jwtToken)
          .catch((e) => {
            console.error(e);
          });
      },
      destroyComponent() {
        pgw.unmount();
        _popup.close();
      },

      redirectByAlias(alias) {
        _state.goto(alias);
        pgw.unmount();
        if (alias.page) {
          _popup.close();
        }
      },

      showNotification(data) {
        _notification$.next({
          type: data.type,
          text: data.message,
        });
      },
      openChat() {
        _system$.next({ action: 'open chat' });
      },
    };

    const init = () => {
      if (!pgw || !_config.pgw?.enabled) {
        return;
      }

      pgw.onLoad = () => {
        pgwLoad$.next(true);
      };

      logout$.subscribe(() => {
        pgw.terminate();
        pgwInitialization$.next(false);
        login$.pipe(take(1)).subscribe(() => {
          initPgw();
        });
      });

      if (!pgw.isLoaded && !_user.status) {
        combineLatest([login$.pipe(take(1)), pgwLoad$]).subscribe(() => {
          initPgw();
        });

        return;
      }
      if (pgw.isLoaded && !_user.status) {
        login$.pipe(take(1)).subscribe(() => {
          initPgw();
        });
        return;
      }
      if (!pgw.isLoaded && _user.status) {
        pgwLoad$.subscribe(() => {
          initPgw();
        });
        return;
      }
      initPgw();
    };

    combineLatest([pgwInitialization$, balanceUpdate$]).subscribe(([, message]) => {
      pgw.setRealBalance(message.data.balance);
    });

    const cashboxInit = (action, amount) => {
      pgwInitSubscribtion?.unsubscribe();
      pgwInitSubscribtion = pgwInitialization$
        .pipe(
          filter((value) => value),
          takeUntil(logout$),
        )
        .subscribe(() => {
          const options = {
            action: action || 'deposit',
            hideCloseButton: $_CONFIG.pgw?.hideCloseButton,
          };
          if (amount) {
            options.preselectedAmount = amount;
          }
          pgw.mount(options);
          pgw.setRealBalance(_user.profile.balance);
          setBlockers();
        });
      return pgwInitialization$;
    };

    function setBlockers() {
      const messages = [];
      if (_user.profile.active_bonus) {
        messages.push({
          title: $translate.instant(`g.active.bonus`),
          text: $translate.instant(`g.withdrawal.bonus`),
          disableTransactions: true,
          cb: () => callbacks.redirectByAlias('bonuses'),
        });
      }
      return pgw.setWithdrawBlockingMessages(messages);
    }

    function initPgw() {
      const initParams = {
        locale: langReplacements[_config.currentLang] || _config.currentLang,
        country: _user.profile.country,
        currency: _user.profile.currency,
        theme: $_CONFIG.pgw?.theme || 'light',
        layoutMode: $_CONFIG.pgw?.layoutMode || 'row',
      };
      pgw
        .init({
          auth: callbacks.getAuthToken,
          params: initParams,
          onClose: callbacks.destroyComponent,
          onDepositSucceed: callbacks.destroyComponent,
          onWithdrawalSucceed: callbacks.destroyComponent,
          onBalanceHistoryClick: () => callbacks.redirectByAlias('history'),
          onNotification: callbacks.showNotification,
          onSupportClick: callbacks.openChat,
        })
        .then(() => {
          pgwInitialization$.next(true);
        })
        .catch((err) => console.error(err));
    }

    return {
      init,
      cashboxInit,
    };
  }
})();
