import http from '../../service/domain/http.js';
import user from '../../service/domain/user.js';
import trustly$ from '../../service/rx/trustly$.js';
import trustlyConfig from '../../service/configs/trustly.js';
import analytics from '../analytics/analytics.js';
import analyticsEvents from '../configs/analytic-events.js';
import heatmap from '../analytics/heatmap.js'

(function () {
  'use strict';

  app.factory('paynplay', controller);

  controller.$inject = ['http', '$q', 'user', 'trustly$', 'trustly.config', 'analytics', 'analyticsEvents', 'heatmap'];

  function controller(_http, $q, _user, _trustly$, _trustly_config, analytics, _analyticsEvents, _heatmap) {
    const adformcookieid = window.Adform?.['_uid'];
    function requestParams(p) {
      const ls = JSON.parse(window.localStorage.getItem('params') || '{}');
      ls.paymethod = 'trustly';
      ls.currency = _trustly_config.currency;
      ls.affiliate = {};
      ls.locale = _trustly_config.locale;
      ls.amount = o.data.amount;
      if (o.data.order_id) ls.order_id = o.data.order_id;
      return Object.assign({}, ls, p);
    }
    function reset() {
      o.data.flow = null;
      o.data.amount = _trustly_config.default;
      o.data.step = 0;
      o.data.order_id = null;
    }

    let _step = 0,
      _flow = null; // 'fast_deposit' - default || 'resume_play' - optional

    const o = {
      data: {
        order_id: null,
        amount: _trustly_config.default,
        reset,
      },

      init(params) {
        const defer = $q.defer(),
          alias = 'trustlyInit',
          config = { meta: { alias } };
        const data = requestParams(params);
        if (adformcookieid) {
          data.adformcookieid = adformcookieid;
        }

        _http.post(alias, data, config).then(
          (a) => {
            this.data.order_id = a.data.result.order_id || null;
            defer.resolve(a.data);
          },
          (a) => {
            defer.reject(a.data);
          }
        );

        return defer.promise;
      },

      auth(params) {
        const defer = $q.defer(),
          alias = 'trustlyAuth',
          config = {
            meta: { alias },
          };
        const data = requestParams(params);
        if (adformcookieid) {
          data.adformcookieid = adformcookieid;
        }
        _http.post(alias, data, config).then(
          (a) => {
            _user.profile = a.data.result;
            analytics.send(_analyticsEvents.registrationSuccess, 'success', a.data.result.login);
            _heatmap.sendEvent('event', 'register');
            _heatmap.sendEvent('identify', _user.profile.account_id);
            defer.resolve(a.data);
          },
          (a) => {
            analytics.send(_analyticsEvents.registrationFail, a.data.messages[0].code?.toString(), 'Not_Login');
            defer.reject(a.data);
          }
        );

        return defer.promise;
      },

      checkStatus(options) {
        let defer = $q.defer(),
          alias = 'trustlyStatus',
          config = {
            params: options,
            cache: false,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data.result);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },

      updateProfile(data) {
        let defer = $q.defer(),
          alias = 'trustlyData',
          config = { meta: { alias } };

        _http.post(alias, data, config).then(
          (answer) => {
            defer.resolve(answer.data.result);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },
    };

    Object.defineProperty(o.data, 'step', {
      get() {
        return _step;
      },
      set(value) {
        _step = value;
        _trustly$.next({ action: 'step', data: value });
      },
    });

    Object.defineProperty(o.data, 'flow', {
      get() {
        return _flow || 'fast_deposit';
      },
      set(value) {
        _flow = value;
      },
    });

    return o;
  }
})();
