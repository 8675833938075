import popup from '../service/domain/popup.js';
import state from '../service/domain/state.js';
(function () {
  'use strict';

  const directive = { name: 'onSuccess' };

  controller.$inject = ['$state', 'popup', 'state'];

  function controller($state, _popup, _state) {
    const link = (scope, element, attrs, formCtrl) => {
      let action = { none: null };

      attrs.$observe(directive.name, (value) => {
        action = scope.$eval(value);
        if (typeof action.params !== 'object') action.params = {};
      });

      formCtrl.$onSuccessful = function () {
        //TODO: modal

        switch (Object.keys(action)[0]) {
          case 'state':
            _popup.close();
            $state.go(action.state, action.params);
            break;

          case 'popup':
            _popup.open({ name: action.popup, content: action.params });
            break;

          case 'popup-close':
            _popup.close();
            break;

          case 'alias':
            _popup.close();
            _state.goto(action.alias);
            break;

          case 'location':
            window.location = `${action.location}?${jQuery.param(action.params)}`;
            break;

          case 'open':
            window.open(`${action.open}`);
            break;

          default:
            break;
        }
      };
    };

    return {
      require: 'form',
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
