import http from "../../service/domain/http.js"
(function () {
  controller.$inject = ['$q', 'http'];

  app.factory('worldCup', controller);

  function controller($q, _http) {
    return {
      getCollection() {
        const defer = $q.defer(),
          alias = 'worldCupCalendar',
          config = {
            params: {},
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },
    };
  }
})();
