import state from '../../service/domain/state.js';
import config from '../../service/configs/config.js';
import system$ from '../../service/rx/system$.js';
import user from '../../service/domain/user.js';
import remembered from '../../service/domain/remembered.js';
(function () {
  'use strict';
  const directive = { name: 'lindaGoto' };

  controller.$inject = ['$location', '$state', 'state', 'config', 'system$', 'user', 'remembered'];

  function controller($location, $state, _state, _config, _system$, _user, _remembered) {
    const link = (scope, element, attrs) => {
      let subscription;

      element.on('click', () => {
        let stateParams;

        try {
          stateParams = scope.$eval(attrs[directive.name]) || attrs[directive.name];
        } catch (error) {
          stateParams = transformAlias(attrs[directive.name]);
        }

        function transformAlias(aliasData) {
          const aliasPart = aliasData.split(',');
          if (aliasPart.length > 1 && aliasPart[0] === 'realGame') {
            return { alias: aliasPart[0], params: { name: aliasPart[1] } };
          }
          return aliasPart[0];
        }

        const alias = typeof stateParams === 'object' ? stateParams.alias : stateParams;
        const aliasInConfig = _config.aliases?.[alias];
        const params = { ...aliasInConfig?.params, ...stateParams?.params };

        const goTo = (name = alias) => (params ? _state.goto(name, params) : _state.goto(name));
        const goToAuthorization = () => {
          const authAlias = _remembered.login ? 'login' : 'registration';
          goTo(authAlias);
        };

        if (alias[0] === '/') {
          $location.path(stateParams);
          scope.$apply();
          return;
        }

        if (alias.search('https://') === 0) {
          window.open(stateParams);
          return;
        }

        if (alias === 'chat') {
          _system$.next({ action: 'open chat' });
          return;
        }

        if (alias === 'authorization') {
          goToAuthorization();
          return;
        }

        if (!_user.status && aliasInConfig?.private) {
          goToAuthorization();
          subscription = _state.readyToRedirectAfterAuth$.subscribe(goTo);
          return;
        }

        goTo();

        scope.$apply();
      });

      scope.$on('$destroy', () => {
        subscription?.unsubscribe();
      });
    };

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
