import account from '../../service/domain/account.js';
import helper from '../../service/helper.js';
import state from '../../service/domain/state.js';
import user from '../../service/domain/user.js';
(function () {
  'use strict';

  const component = { name: 'swedenProfileLoginLimits' };

  controller.$inject = ['$scope', 'account', 'helper', 'state', 'user'];

  function controller($scope, _account, _helper, _state, _user) {
    $scope.preloader = false;

    $scope.formData = {
      day: _user.profile.limits.login_time.day.amount || '',
      week: _user.profile.limits.login_time.week.amount || '',
      month: _user.profile.limits.login_time.month.amount || '',
    };

    $scope.formField = {
      day: { min: 1, max: 24 },
      week: { min: 1, max: 168 },
      month: { min: 1, max: 744 },
    };

    $scope.submit = _helper.form.validator((form) => {
      $scope.preloader = true;
      const params = [
        { type: 'day', group: 'login_time', amount: $scope.formData.day },
        { type: 'week', group: 'login_time', amount: $scope.formData.week },
        { type: 'month', group: 'login_time', amount: $scope.formData.month },
      ];
      _account
        .multiLimits(params)
        .then((a) => {})
        .catch((e) => {})
        .finally(() => {
          $scope.preloader = false;
        });
    });

    $scope.cancelLimit = (param) => {
      $scope.preloader = true;

      const params = {
        type: param,
        group: 'login_time',
      };
      _account
        .cancelLimit(params)
        .then((a) => {})
        .catch((e) => {})
        .finally(() => {
          $scope.preloader = false;
        });
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
