import account from '../../service/domain/account.js';
import helper from '../../service/helper.js';
import state from '../../service/domain/state.js';
import user from '../../service/domain/user.js';
(function () {
  'use strict';

  const component = { name: 'swedenInitialTimeLimitForm' };

  controller.$inject = ['$scope', 'account', 'helper', 'state', 'user'];

  function controller($scope, _account, _helper, _state, _user) {
    $scope.preloader = false;

    $scope.formData = {
      day: _user.profile.limits.login_time.day.amount || 12,
      week: _user.profile.limits.login_time.week.amount || 70,
      month: _user.profile.limits.login_time.month.amount || 360,
    };

    $scope.formField = {
      day: { min: 1, max: 12 },
      week: { min: 1, max: 70 },
      month: { min: 1, max: 360 },
    };

    $scope.defaultLimits = {
      day: [4, 8, 12],
      week: [20, 40, 70],
      month: [120, 240, 360],
    };

    $scope.submit = _helper.form.validator((form) => {
      $scope.preloader = true;
      const params = [
        { type: 'day', group: 'login_time', amount: $scope.formData.day },
        { type: 'week', group: 'login_time', amount: $scope.formData.week },
        { type: 'month', group: 'login_time', amount: $scope.formData.month },
      ];
      _account
        .multiLimits(params)
        .then((a) => {
          _state.goback();
        })
        .catch((e) => {})
        .finally(() => {
          $scope.preloader = false;
        });
    });
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
