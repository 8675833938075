import helper from '../../service/helper.js';
import account from '../../service/domain/account.js';
import user from '../../service/domain/user.js';
(function () {
  'use strict';

  const component = { name: 'swedenProfileSessionLimit' };

  controller.$inject = ['$scope', 'helper', 'account', 'user'];

  function controller($scope, _helper, _account, _user) {
    /*
        UNITS
    case '1':
        return 'minutes';
    case '2':
        return 'hours';
    case '3':
        return 'days';
    case '4':
        return 'weeks';
    case '5':
        return 'months';
*/
    $scope.editMode = false;
    $scope.preloader = false;
    $scope.formData = {
      units: '',
      session_limit: '',
    };
    if (typeof _user.profile.session_limit.value === 'string') {
      $scope.formData.units = _user.profile.session_limit.value[0];
      $scope.formData.session_limit = +_user.profile.session_limit.value.slice(1);
    }

    $scope.cancel = () => {
      $scope.editMode = false;
      $scope.formData.units = _user.profile.session_limit.value[0];
      $scope.formData.session_limit = parseInt(_user.profile.session_limit.value.slice(1));
    };

    $scope.submit = _helper.form.validator((form) => {
      const o = {
        session_limit: parseInt(`${$scope.formData.units}${$scope.formData.session_limit}`) + '',
      };

      $scope.preloader = true;
      _account
        .sessionLength(o)
        .then(
          (a) => {
            $scope.formData.units = _user.profile.session_limit.value[0];
            $scope.formData.session_limit = parseInt(_user.profile.session_limit.value.slice(1));
            $scope.editMode = false;
          },
          (a) => {}
        )
        .finally(() => {
          $scope.preloader = false;
        });
    });
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
