import config from '../../service/configs/config.js';
(function () {
  'use strict';

  const directive = { name: 'lindaGotoLang' };

  controller.$inject = ['$location', 'config'];

  function controller($location, _config) {
    const link = (scope, element, attrs) => {
      const linkElement = element[0];

      const error = {
        wrongElement: 'Incorrectly selected tag for linda-goto-lang. Must be a tag - a',
        emptyLangParam: '',
      };

      if (linkElement.localName !== 'a') {
        alert(error.wrongElement);
        return;
      }

      const langTo = scope.$eval(attrs[directive.name]);

      function setHref(lng) {
        const regStr = new RegExp(`^\/${_config.currentLang}\/`);
        const url = $location.url().replace(regStr, `/${lng}/`);

        linkElement.setAttribute('href', url);
      }

      setHref(langTo);

      linkElement.setAttribute('target', '_self');

      if (langTo === _config.currentLang) {
        linkElement.classList.add('active');
      }

      scope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
        setHref(langTo);
      });
    };

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
