import user$ from '../service/rx/user$.js';
import user from '../service/domain/user.js';
(function () {
  'use strict';

  controller.$inject = ['user$', 'user', '$timeout'];

  function controller(_user$, _user, $timeout) {
    function link(scope, element, attrs) {
      let variable =
        attrs.getUserToScope && attrs.getUserToScope !== 'get-user-to-scope' ? attrs.getUserToScope : 'user';

      scope.selectValue = function (val1, val2) {
        if (_user.status === true) {
          return val1;
        } else if (_user.status === false) {
          return val2;
        } else {
          return null;
        }
      };

      scope[variable] = _user;

      _user$.subscribe((data) => {
        $timeout(() => {
          scope[variable] = data;
        }, 0);
      });
    }

    return {
      restrict: 'A',
      link: link,
    };
  }

  app.directive('getUserToScope', controller);
})();
