import user from '../../service/domain/user.js';
(function () {
  'use strict';

  const directive = { name: 'openChatMobile' };

  controller.$inject = ['user'];

  function controller(_user) {
    const link = (scope, element, attrs) => {
      let tapping = false;

      const doIt = () => {
        (function (d, src, c) {
          var t = d.scripts[d.scripts.length - 1],
            s = d.createElement('script');
          s.id = 'la_x2s6df8d';
          s.async = true;
          s.src = src;
          s.onload = s.onreadystatechange = function () {
            var rs = this.readyState;
            if (rs && rs != 'complete' && rs != 'loaded') {
              return;
            }
            c(this);
          };
          t.parentElement.insertBefore(s, t.nextSibling);
        })(document, 'https://soft2bet.ladesk.com/scripts/track.js', function (e) {
          if (_user.status) {
            const email = _user.profile.email_guid || _user.profile.email;
            LiveAgent.setUserDetails(
              email,
              _user.profile.profile.name,
              _user.profile.profile.surname,
              _user.profile.phone
            );
            LiveAgent.addTicketField('Username', _user.profile.login);
          }
          let chatButton = LiveAgent.createButton('24d327d2', e);
          let intrvl = setInterval(() => {
            if (chatButton.form) {
              window.open(chatButton.form.url, '_system');
              clearInterval(intrvl);
            }
          }, 10);
        });
      };

      element.bind('touchstart', function (e) {
        tapping = true;
        element.addClass('ng-tap--start');
      });

      element.bind('touchmove', function (e) {
        tapping = false;
      });

      element.bind('touchend', function (e) {
        element.removeClass('ng-tap--start');
        if (tapping) {
          doIt();
        }
      });
    };

    return {
      link,
    };
  }

  app.directive(directive.name, controller);
})();
