import history from '../../service/domain/history.js';
(function () {
  'use strict';

  const component = { name: 'swedenProfileHistory' };

  controller.$inject = ['$scope', 'history'];

  function controller($scope, _history) {
    $scope.preloader = false;
    $scope.collection = [];

    $scope.preloader = true;
    _history
      .balanceFlow({})
      .then((a) => {
        $scope.collection = a.result;
      })
      .catch((a) => {})
      .finally(() => {
        $scope.preloader = false;
      });
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
