import banner from '../../service/domain/banner.js';
(function () {
  'use strict';

  const component = { name: 'lindaBanner' };

  controller.$inject = ['$scope', 'banner'];

  function controller($scope, _banner) {
    $scope.preloader = false;
    $scope.banner = {};

    function getBanner(o) {
      $scope.preloader = true;
      _banner.collection(o).then(
        (answer) => {
          $scope.banner = answer.result[0];
          $scope.preloader = false;
        },
        (answer) => {
          $scope.preloader = false;
        }
      );
    }

    this.$onChanges = () => {
      const p = {
        category: 'banner',
        place: this.place,
        out: this.out || 'text,title',
      };

      getBanner(p);
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
    bindings: {
      place: '<',
      out: '<',
    },
  });
})();
