import user from '../../service/domain/user.js';
(function () {
  'use strict';

  controller.$inject = ['user', '$q'];

  function controller(_user, $q) {
    let csrf_token = '';

    return {
      request: function (config) {
        if (config.meta && config.meta.type === 'api') {
          switch (config.method) {
            case 'POST':
            case 'PUT':
            case 'DELETE':
            case 'PATCH':
              config.headers['csrf-token'] = `${csrf_token}`;
              break;
            default:
              break;
          }
        }

        return config;
      },

      response: function (response) {
        const headers = response.headers();

        if (headers['csrf-token'] && headers['csrf-token'].length >= 5) csrf_token = headers['csrf-token'];
        return response;
      },

      responseError(rejection) {
        return $q.reject(rejection);
      },
    };
  }

  app.factory('csrf', controller);
})();
