import brand from '../../service/domain/brand.js';
(function () {
  const component = { name: 'lindaSitemap' };

  controller.$inject = ['$scope', 'brand'];

  function controller($scope, _brand) {
    $scope.preloader = false;
    $scope.providerList = [];

    $scope.preloader = true;
    _brand
      .collection()
      .then(({ result }) => {
        $scope.providerList = result;
      })
      .catch(() => {})
      .finally(() => {
        $scope.preloader = false;
      });
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
