import config from '../../service/configs/config.js';
(function () {
  'use strict';

  app.factory('promo.config', controller);

  controller.$inject = ['config'];

  function controller(_config) {
    const config = {
      //            halloween : {  startDate: '2019-10-25', endDate: '2019-10-31'  },
      halloween_shop: { startDate: '2019-10-25', endDate: '2019-11-15' },
      christmas: { startDate: '2020-12-01', endDate: '2020-12-31' },
    };

    const promo = {};

    Object.keys(config).forEach((promoName) => {
      if (
        _config.system_info.date > config[promoName].startDate &&
        _config.system_info.date < config[promoName].endDate
      ) {
        promo[promoName] = config[promoName];
      }
    });

    return promo;
  }
})();
