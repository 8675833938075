import http from '../../service/domain/http.js';
import user from '../../service/domain/user.js';
(function () {
  app.factory('achievement', controller);
  controller.$inject = ['http', '$q', 'user'];

  function controller(_http, $q, _user) {
    return {
      take(data) {
        const defer = $q.defer(),
          alias = 'achievementTake',
          config = { meta: { alias } };

        _http.post(alias, data, config).then(
          (answer) => {
            _user.profile = answer.data.result;
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },

      lastEarned(options) {
        let defer = $q.defer(),
          alias = 'achievementLastEarned',
          config = {
            params: options,
            cache: false,
            meta: {
              type: 'api',
              alias,
            },
          };
        _http.get(alias, config).then(
          (a) => {
            defer.resolve(a.data);
          },
          (a) => {
            defer.reject(a.data);
          }
        );
        return defer.promise;
      },

      inProgress(options) {
        let defer = $q.defer(),
          alias = 'achievementInProgress',
          config = {
            params: options,
            cache: false,
            meta: {
              type: 'api',
              alias,
            },
          };
        _http.get(alias, config).then(
          (a) => {
            defer.resolve(a.data);
          },
          (a) => {
            defer.reject(a.data);
          }
        );
        return defer.promise;
      },

      item(params) {
        const defer = $q.defer();
        const alias = 'achievementItem';
        const config = {
          params,
          meta: {
            type: 'api',
            alias,
          },
        };

        _http
          .get(alias, config)
          .then(({ data }) => defer.resolve(data))
          .catch(({ data }) => defer.reject(data));

        return defer.promise;
      },

      list(options) {
        let defer = $q.defer(),
          alias = 'achievements',
          config = {
            params: options,
            cache: false,
            meta: {
              type: 'api',
              alias,
            },
          };
        _http.get(alias, config).then(
          (a) => {
            defer.resolve(a.data);
          },
          (a) => {
            defer.reject(a.data);
          }
        );
        return defer.promise;
      },
    };
  }
})();
