import tournament from '../../service/domain/tournament.js';
(function () {
  'use strict';

  const component = { name: 'lindaTournaments' };

  controller.$inject = ['$scope', 'tournament'];

  function controller($scope, _tournament) {
    $scope.tournaments = [];
    $scope.preloader = true;

    this.$onChanges = function () {
      const options = {};

      options.showPlayersInfo = this.players;
      options.max_members = this.competitors;

      _tournament.list(options).then(
        function (answer) {
          $scope.tournaments = answer.result;
          $scope.preloader = false;
        },
        function (answer) {
          $scope.preloader = false;
        }
      );
    };
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
    bindings: {
      players: '<',
      competitors: '<',
    },
  });
})();
