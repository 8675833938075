import account from '../../service/domain/account.js';
import state from '../../service/domain/state.js';
(function () {
  'use strict';

  const component = { name: 'swedenProfileSelfExclusion' };

  controller.$inject = ['$scope', 'account', 'state'];

  function controller($scope, _account, _state) {
    $scope.preloader = false;
    $scope.formData = {};

    $scope.submit = () => {
      $scope.preloader = true;
      _account
        .exclude($scope.formData)
        .then((a) => {
          _state.goto('home');
        })
        .catch((e) => {})
        .finally(() => {
          $scope.preloader = false;
        });
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
