import http from './http.js';
import system$ from '../rx/system$.js';
import popup from './popup.js';
import { filter } from 'rxjs/operators';

export const optIn = 'optIn';

class OptIn {
  static $inject = ['http', 'system$', 'popup'];

  constructor(_http, _system$, _popup) {
    this.http = _http;
    this.system$ = _system$;
    this.popup = _popup;
  }

  init() {
    return this.system$
      .pipe(
        filter((message) => message.action === 'login')
      )
      .subscribe(() => {
        this.newlyBonuses().then(({ result }) => {
          if (result.length !== 0) {
            this.popup.open({ name: 'newly-bonuses', content: result });
          }
        });
      });
  }

  newlyBonuses() {
    return this.http.getData('userNewlyBonuses');
  }
}

app.service(optIn, OptIn);
