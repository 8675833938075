import {gsap} from 'gsap';
import ScrollMagic from 'scrollmagic';
import {ScrollToPlugin} from 'gsap/ScrollToPlugin';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

import wallOfWins$ from "../../service/gamification/wall-of-wins.js"
import wallOfWins from "../../service/gamification/wall-of-wins.js"
import ws$ from "../../service/rx/ws$.js"

(function () {
  // example to use
  // wall-of-wins="{width: 102, height: 152, gap: 12, rows: 4, speed: 10, mincount: 95}"
  // wall-of-wins-random="{min: 0, max: 0, intervalTime: 500, rows: 4}"

  const directive = {name: 'wallOfWins'};

  controller.$inject = ['wallOfWins$', 'wallOfWins', 'ws$'];

  function controller(_wallOfWins$, _wallOfWins, _ws$) {
    function compile() {
      return function (scope, element, attrs, ctrl, $transclude) {
        const destroy$ = new Subject();
        const itemParams = scope.$eval(attrs[directive.name]);

        const Nodes = {};
        const Timeline = gsap.timeline({
          repeat: -1,
          paused: false,
        });

        Timeline.add('items', 0);

        /*SCROLL CONTROL*/
        let ScrollController = new ScrollMagic.Controller({});
        gsap.registerPlugin(ScrollToPlugin);

        let sceneTop = new ScrollMagic.Scene({
          triggerHook: 0,
          triggerElement: element[0],
        }).addTo(ScrollController);
        let sceneBottom = new ScrollMagic.Scene({
          triggerHook: 1,
          triggerElement: element[0],
        }).addTo(ScrollController);

        sceneBottom.on('start', function (event) {
          if (event.scrollDirection === 'FORWARD') Timeline.play();
          if (event.scrollDirection === 'REVERSE') Timeline.pause();
        });
        sceneTop.on('end', function (event) {
          if (event.scrollDirection === 'FORWARD') Timeline.pause();
          if (event.scrollDirection === 'REVERSE') Timeline.play();
        });
        /*SCROLL CONTROL*/

        let CountOnScreen = 0;
        let collection = [];
        let lastIndex = 0;
        let viewportWidth = document.documentElement.offsetWidth;

        _wallOfWins
          .collection({
            mincount: itemParams.mincount,
          })
          .then((answer) => {
            collection = (answer.result || []).splice(0, itemParams.mincount);
            run();
          });

        function run() {
          CountOnScreen = (Math.ceil(viewportWidth / (itemParams.width + itemParams.gap)) + 1) * itemParams.rows;
          addMore(CountOnScreen);
          _wallOfWins$.next({
            event: 'random_diapason',
            data: {
              min: 0,
              max: CountOnScreen,
            },
          });
        }

        function addMore(count, newItem = false) {
          const startIndex = lastIndex;
          const endIndex = lastIndex + count;
          for (let i = startIndex; i <= endIndex; i++) {
            addNext(newItem);
          }
          Timeline.play();
        }

        function getRandomIndex(max) {
          return Math.floor(Math.random() * max);
        }

        function addNext(newItem = false) {
          $transclude(scope.$new(true), (clone, scope) => {
            scope.index = lastIndex;
            scope.item =
              collection.length > lastIndex
                ? collection[lastIndex]
                : newItem
                  ? collection[collection.length - 1]
                  : collection[getRandomIndex(collection.length - 1)];
            Nodes[lastIndex] = clone[0];
          });
          element[0].appendChild(Nodes[lastIndex]);
          setItemPosition(lastIndex);
          addAnimation(lastIndex);
          lastIndex++;
        }

        function setItemPosition(index) {
          let top = (index % itemParams.rows) * (itemParams.height + itemParams.gap);
          let left = Math.floor(index / itemParams.rows) * (itemParams.width + itemParams.gap);
          let way = left + itemParams.width + itemParams.gap;

          gsap.set(Nodes[index], {
            x: left,
            y: top,
          });

          const gsapObject = {
            x: -itemParams.width - itemParams.gap,
            duration: way / itemParams.speed,
            ease: 'none',
            onComplete: () => {
              removeItem(index);
            },
          };
          if (index === collection.length - 1) {
            gsapObject.modifiers = {
              x: gsap.utils.unitize(function (x) {
                if (lastIndex === index + 1 && x <= viewportWidth - itemParams.width - itemParams.gap) {
                  Timeline.pause();
                }
                return x;
              }),
            };
          }

          Nodes[index].tween = gsap.to(Nodes[index], gsapObject);
          Timeline.add(Nodes[index].tween, 'items');
        }

        function addAnimation(index) {
          Nodes[index].tweenActive = gsap.to(Nodes[index], {
            scale: 1.3,
            duration: 0.3,
            yoyo: true,
            repeat: 1,
            repeatDelay: 1,
            paused: true,
            ease: Circ.easeInOut,
            onStart() {
              Nodes[index].classList.add('is-active');
            },
            onComplete() {
              if (Nodes[index]) Nodes[index].classList.remove('is-active');
              _wallOfWins$.next({
                event: 'remove',
                data: {
                  index: index,
                },
              });
            },
          });
        }

        function removeItem(index) {
          Timeline.remove(Nodes[index].tween);
          Nodes[index].remove();
          delete Nodes[index];
          addNext();
          _wallOfWins$.next({
            event: 'random_diapason',
            data: {
              min: index + 1,
              max: index + 1 + CountOnScreen,
            },
          });
        }

        _ws$
          .pipe(
            filter((item) => item.event === 'new_win_list'),
            takeUntil(destroy$)
          )
          .subscribe((message) => {
            // const newData = (message.data ? message.data : []).splice( 0, itemParams.rows );
            const newData = message.data || [];
            const indexes = collection.reduce((indexesArray, currentElement, currentIndex) => {
              if (currentElement?.game?.alias === newData[0]?.game?.alias) {
                return [...indexesArray, currentIndex];
              }

              return indexesArray;
            }, []);

            if (indexes.length) {
              indexes.forEach((index) => Object.assign(collection[index], newData[0]));

              for (let key in Nodes) {
                newData.forEach((nd) => {
                  let found = false;

                  if (Nodes[key]?.href?.endsWith(`/${nd?.game.alias}`) && !found) {
                    found = !found;
                    Nodes[key]?.tweenActive.play(0);
                  }
                });
              }
            } else {
              if (collection.length === 100) {
                collection.splice(0, newData.length);
              }

              collection = collection.concat(newData);

              addMore(newData.length, true);
            }
          });

        _wallOfWins$
          .pipe(
            filter((o) => o.event === 'new'),
            filter((o) => Nodes[o.data.index]),
            takeUntil(destroy$)
          )
          .subscribe((o) => {
            Nodes[o.data.index].tweenActive.play(0);
          });

        scope.$on('$destroy', () => {
          destroy$.next();
          destroy$.unsubscribe();
          Timeline.kill();
          ScrollController.destroy(true);
          ScrollController = null;
        });
      };
    }

    return {
      restrict: 'A',
      transclude: true,
      compile,
    };
  }

  app.directive(directive.name, controller);
})();
