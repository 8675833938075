import system from '../../../service/domain/system.js';
import state from '../../../service/domain/state.js';
import paynplay from '../../../service/trustly/paynplay.js';
import popup from '../../../service/domain/popup.js';
(function () {
  'use strict';

  const directive = { name: 'paynplayResumePlayButton' };

  factory.$inject = ['system', 'state', 'paynplay', 'popup'];

  function factory(_system, _state, _paynplay, _popup) {
    function link(scope, element, attrs) {
      element.on('click', (e) => {
        _paynplay.data.flow = 'resume_play';
        _paynplay.data.amount = 0;
        _paynplay.data.step = 1;
        _state.goto('paynplay');
        _popup.close();
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, factory);
})();
