import paynplay from '../../../service/trustly/paynplay.js';
import config from '../../../service/configs/config.js';
import user from '../../../service/domain/user.js';
import state from '../../../service/domain/state.js';
import helper from '../../../service/helper.js';
import trustly$ from '../../../service/rx/trustly$.js';
import trustlyConfig from '../../../service/configs/trustly.js';
import {filter} from "rxjs/operators";

(function () {
  'use strict';

  const component = { name: 'lindaTrustlyPaynplayPage' };

  controller.$inject = [
    '$scope',
    'paynplay',
    'config',
    'user',
    'state',
    'helper',
    'trustly$',
    '$timeout',
    'trustly.config',
    '$window',
  ];

  function controller(
    $scope,
    _paynplay,
    _config,
    _user,
    _state,
    _helper,
    _trustly$,
    $timeout,
    _trustly_config,
    $window
  ) {
    const redirectToHomepage = () => _state.goto('home');
    let loginSubscription;

    if (!_trustly_config?.enabled || _user.status) {
      redirectToHomepage();
      return;
    }
    loginSubscription = _system$.pipe(filter(({ action }) => action === 'login')).subscribe(redirectToHomepage);

    $scope.amount = _paynplay.data.amount;
    $scope.step = _paynplay.data.step;
    $scope.flow = _paynplay.data.flow;
    $scope.paysystem = _trustly_config;

    let subscription;

    $scope.submit = _helper.form.validator((form) => {
      if ($scope.amount > 0) {
        _paynplay.data.amount = $scope.amount;
        _paynplay.data.step = 1;
      }
    });

    function handler(event) {
      if (event.origin !== 'https://trustly.com') {
        return;
      }

      const data = JSON.parse(event.data);

      if (data.method === 'OPEN_APP') {
        $window.location.assign(data.appURL);
      }
    }

    this.$onInit = () => {
      subscription = _trustly$
        .pipe(filter((item) => item.action === 'step'))
        .subscribe((item) => {
          $timeout(() => {
            $scope.step = item.data;
          });
        });
      $window.addEventListener('message', handler, false);
    };

    this.$onDestroy = () => {
      _paynplay.data.reset();
      subscription.unsubscribe();
      loginSubscription?.unsubscribe();
      $window.removeEventListener('message', handler, false);
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
