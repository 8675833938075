import { holidayPromo } from '../service/periodicals/promoHolidayService.js';
import user from '../service/domain/user.js';
import state from '../service/domain/state.js';

(function () {
  'use strict';

  const directive = { name: 'holidayLink' };

  controller.$inject = ['holidayPromo', 'user', 'state'];

  function controller(holidayPromo, _user, _state) {
    function link(scope, element, attrs, ctrl, transclude) {
      scope.holidayCollection = {};
      const promoAlias = attrs[directive.name] || '';

      holidayPromo.getPromoList().then((data) => {
        if (promoAlias && holidayPromo[promoAlias]) {
          transclude(scope, (clone) => {
            element[0].appendChild(clone[0], element[0].nextSibling);
            clone[0].onclick = () => {
              if (_user.status) {
                return _state.goto('bonus_crab', { name: promoAlias });
              }
              _state.goto('promos', { name: promoAlias });
            };
          });

          scope.holidayCollection = {
            [promoAlias]: data[promoAlias],
          };
        }
      });
    }

    return {
      restrict: 'A',
      link,
      transclude: true,
    };
  }

  app.directive(directive.name, controller);
})();
