import http from '../../service/domain/http.js';
import user from '../../service/domain/user.js';
(function () {
  'use strict';

  app.factory('bank', controller);

  controller.$inject = ['http', '$q', 'user'];

  function controller(_http, $q, _user) {
    return {
      entercash: {
        formData: {
          flowType: 'signup_dep', //signup_dep || signin_bank || signup_nodep ||
          currency: 'SEK',
          amount: null,
        },
      },

      init(credentials) {
        const defer = $q.defer(),
          alias = 'bankInit',
          config = { meta: { alias } },
          ls = JSON.parse(window.localStorage.getItem('params') || '{}');
        delete ls.amount;
        const data = Object.assign({}, ls, credentials);

        _http.post(alias, data, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },

      auth(credentials) {
        const defer = $q.defer(),
          alias = 'bankAuth',
          config = {
            meta: { alias },
          },
          ls = JSON.parse(window.localStorage.getItem('params') || '{}');
        delete ls.amount;
        const data = Object.assign({}, ls, credentials);

        _http.post(alias, data, config).then(
          (answer) => {
            _user.profile = answer.data.result.account;
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },
    };
  }
})();
