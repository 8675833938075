import config from "../../service/configs/config.js"
(function () {
  'use strict';

  controller.$inject = ['config'];

  function controller(_config) {
    return {
      request(config) {
        if (_config.mobileConfig.mobile && config.meta && config.meta.type === 'api') {
          config.headers['S2B-APP-Version'] = _config.mobileConfig.version;
        }
        return config;
      },
    };
  }

  app.factory('headers', controller);
})();
