import timer$ from '../../service/rx/timer$.js';
(function () {
  'use strict';

  controller.$inject = ['timer$', '$filter'];

  function controller(_timer$, $filter) {
    const link = (scope, element, attrs) => {
      const format = 'HH:mm:ss';
      let timer = 0;

      element[0].innerHTML = $filter('date')(new Date(), format);

      _timer$.subscribe((item) => {
        element[0].innerHTML = $filter('date')(new Date(), format);
      });
    };

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive('lindaClock', controller);
})();
