import game from '../../service/domain/game.js';
import user from '../../service/domain/user.js';
import system$ from '../../service/rx/system$.js';
import { Subject, ReplaySubject } from 'rxjs';
import { filter, exhaustMap } from 'rxjs/operators';

(function () {
  controller.$inject = ['game', 'user', 'system$'];

  function controller(_game, _user, _system$) {
    const count = 1;
    let hasGame = false;
    const recentlyPlayed$ = new ReplaySubject();
    const requestGame$ = new Subject();

    requestGame$
      .pipe(
        exhaustMap(() => requestGame()),
        filter(({ total_count }) => total_count > 0),
      )
      .subscribe(() => {
        hasGame = true;
        recentlyPlayed$.next(hasGame);
      });

    const requestGame = () => {
      return _game.recentlyPlayed({ count });
    };

    const checkRecentlyPlayed = () => {
      if (!_user.status) {
        hasGame = false;
        recentlyPlayed$.next(hasGame);
        return;
      }
      if (_user.status && !hasGame) {
        requestGame$.next();
      }
    };

    _system$.pipe(filter((event) => event.action === 'statusChanged')).subscribe(() => {
      checkRecentlyPlayed();
    });

    return {
      recentlyPlayed$,
      checkRecentlyPlayed,
    };
  }

  app.factory('recentlyPlayed', controller);
})();
