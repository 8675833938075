import account from '../domain/account.js';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

(function () {
  controller.$inject = ['account', '$q'];
  function controller(_account, $q) {
    const stream$ = new Subject();
    const minLength = 6;
    const maxLength = 15;

    stream$.pipe(debounceTime(400)).subscribe(({ options, defer }) => {
      _account
        .validateUserData(options)
        .then(() => {
          defer.resolve();
        })
        .catch((err) => {
          defer.reject(err);
        });
    });

    return {
      asyncCheckPhone(value) {
        const lastSymbolValue = /\d$/.test(value);
        if (lastSymbolValue && value.length >= minLength && value.length <= maxLength) {
          const defer = $q.defer();
          stream$.next({ options: { phone: value }, defer });
          return defer.promise;
        }
        const defer = $q.defer();
        defer.reject();
        return defer.promise;
      },
      asyncCheckLogin(value) {
        const defer = $q.defer();
        stream$.next({ options: { login: value }, defer });
        return defer.promise;
      },
    };
  }

  app.factory('accountValidator', controller);
})();
