import cookie$ from '../../../service/rx/cookie$.js';
import config from '../../../service/configs/config.js';
import { filter } from 'rxjs/operators';

(function () {
  'use strict';

  const component = {
    name: 'lindaCookieBanner',
  };

  controller.$inject = ['$scope', '$element', 'cookie$', 'config', '$document', '$window'];

  function controller($scope, $element, cookie$, _config, $document, $window) {
    const initCookieScriptViaGtag = _config.feature?.initCookieScriptViaGtag;

    if (!initCookieScriptViaGtag) initCookieScript();

    hideCookieBanner();

    let cookieScriptBtn;
    const hideBanner = () => {
      $element[0].style.display = 'none';
    };
    const showBanner = () => {
      $element[0].style.display = 'block';
    };

    hideBanner();

    const initBanner = () => {
      cookieScriptBtn = $document[0].getElementById('cookiescript_manage_wrap');
      if (cookieScriptBtn) {
        showBanner();
        return;
      }
      hideBanner();
    };
    const subscription = cookie$.pipe(filter((data) => data.event === 'banner_open')).subscribe((data) => {
      showBanner();
      CookieScript.instance.show();
    });

    $window.addEventListener('CookieScriptLoaded', () => {
      initBanner();
    });

    $scope.acceptAll = () => {
      hideBanner();
      CookieScript.instance.acceptAllAction();
    };
    $scope.openCookieSettings = () => {
      $document[0].getElementById('cookiescript_manage_wrap').click();
      hideBanner();

      /* This block is a workaround for IOS safari scroll popup issue. ADV-12761 */
      try {
        const languageScroller = $document[0].querySelector('.cookiescript_custom_select_items');
        const cookieDataScroller = $document[0].querySelector('.cookiescript_fsd_tabs_content');

        bodyScrollLock.disableBodyScroll(languageScroller, { allowTouchMove: () => true });
        bodyScrollLock.disableBodyScroll(cookieDataScroller, { allowTouchMove: () => true });

        const enableBodyScrollerForPopupElements = () => {
          try {
            bodyScrollLock.enableBodyScroll(languageScroller);
            bodyScrollLock.enableBodyScroll(cookieDataScroller);
          } catch (error) {
            /* empty */
          }
        };

        CookieScript.instance.onAccept = enableBodyScrollerForPopupElements;
        CookieScript.instance.onAcceptAll = enableBodyScrollerForPopupElements;
        CookieScript.instance.onReject = enableBodyScrollerForPopupElements;
      } catch (error) {
        /* empty */
      }
    };

    function hideCookieBanner() {
      const styleElement = $document[0].createElement('style');
      styleElement.innerHTML = '#cookiescript_injected, #cookiescript_injected_wrapper{display: none !important;}';
      $document[0].body.append(styleElement);
    }

    function checkIfDevEnv() {
      const envsRegExp = new RegExp(/(^dev\..*|.*\.(devv2|rabidi).space|.fbarax.tech)$/);
      const { host } = $window.location;
      return envsRegExp.test(host);
    }

    function initCookieScript() {
      const cookieScript = $document[0].createElement('script');
      cookieScript.charset = 'UTF-8';
      cookieScript.type = 'text/javascript';
      const deviceType = _config.device.mobile ? 'mobile' : 'desktop';
      const isDevEnv = checkIfDevEnv();

      const idName = !_config.production
        ? `${deviceType}Dev`
        : _config.production && isDevEnv
        ? `${deviceType}Dev`
        : `${deviceType}`;

      cookieScript.src =
        $_CDN.cookieBanner.cdn +
        ($_CDN.cookieBanner[idName] ? $_CDN.cookieBanner[idName] : $_CDN.cookieBanner[deviceType]) +
        '.js';

      cookieScript.setAttribute('data-cs-lang', _config.currentLang);
      $document[0].getElementsByTagName('body')[0].append(cookieScript);
    }

    this.$onDestroy = () => {
      subscription.unsubscribe();
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
    bindings: {},
  });
})();
