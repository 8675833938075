import config from '../../service/configs/config.js';
import user from '../../service/domain/user.js';
import user$ from '../../service/rx/user$.js';
import ws$ from '../../service/rx/ws$.js';
import splash$ from '../../service/rx/splash$.js';
import helper from '../../service/helper.js';
import wsEvents from '../../service/configs/ws-events.js';
import { filter, take } from 'rxjs/operators';
import system$ from '../../service/rx/system$.js';

(function () {
  'use strict';

  app.factory('ws', controller);

  controller.$inject = ['config', '$q', 'user', 'user$', 'ws$', 'splash$', '$state', 'helper', 'wsEvents', 'system$'];

  function controller(_config, $q, _user, _user$, _ws$, _splash$, $state, _helper, _wsEvents, _system$) {
    let _subscribed = false;
    let _subscriptionToken = null;

    const options = {
      socket: {},
      messages: [],
      construct,
    };

    _splash$
      .pipe(
        filter((message) => message === 'loaded'), take(1))
      .subscribe((i) => {
        const wsScript = $_CONFIG.production ? $_CONFIG.wsScript : `${$_CONFIG.domain}/${$_CONFIG.wsScript}`;
        _helper.dom.addScript(wsScript, () => {
          options.construct({ lang: $state.params.lang });
        });
      });

    _system$
      .pipe(
        filter((message) => message.action === 'login')
      )
      .subscribe(() => {
        if(options.socket.disconnect) {
          options.construct({ lang: $state.params.lang });
        }
      });

    _system$
      .pipe(
        filter((message) => message.action === 'logout')
      )
      .subscribe(() => {
        options.socket.close();
      });

    function subscribe() {
      const _subscriptionNeeded =
        _user.status && (!_subscribed || _subscriptionToken !== _user.sessionToken) ? true : false;
      if (_subscriptionNeeded) {
        const params = { authorization: 'Basic ' + _user.sessionToken };
        options.socket.emit('ws:account/subscribe', params, (answer) => {
          if (answer.status === 200) {
            _subscribed = true;
            _subscriptionToken = _user.sessionToken;
          }
        });
        return;
      }
      _subscribed = false;
    }

    // in linda-app
    function construct(params) {
      const def = $q.defer();

      try {
        options.socket = io(_config.apiUrl, { path: '/socket', query: params, transports: ['websocket', 'polling'] });

        options.socket.on('connect', () => {
          subscribe();
          _user$.subscribe(subscribe);
          def.resolve(true);
        });

        options.socket.on('error', (error) => {
          console.error('========================== O N   E R R O R ------------------------>', error);
          _subscribed = false;

          def.reject(error);
        });

        options.socket.on('disconnect', (message) => {
          console.error('========================== O N   D I S C O N N E C T ------------------------>', message);
          _subscribed = false;
        });

        Object.values(_wsEvents).forEach((event) => {
          options.socket.on(event, (data) => {
            _ws$.next({ event, data });
          });
        });
      } catch (e) {
        console.error('========================== T R Y      E R R O R ------------------------>', e);
        _subscribed = false;
      }

      return def.promise;
    }
    return options;
  }
})();
