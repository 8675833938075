import config from '../../service/configs/config.js';
import state from '../../service/domain/state.js';
import banner from '../../service/domain/banner.js';
import game from '../../service/domain/game.js';
import popup from '../../service/domain/popup.js';
import user from '../../service/domain/user.js';
import account from '../../service/domain/account.js';
(function () {
  'use strict';

  const directive = { name: 'bonusCrabInit' };

  controller.$inject = ['$state', 'config', 'state', 'banner', 'game', 'popup', 'user', 'account'];

  function controller($state, _config, _state, _banner, _game, _popup, _user, _account) {
    const link = (scope, element) => {
      if (_config.feature?.crab_sdk?.visible === false) {
        _state.goto('home');
        return;
      }

      scope.preloader = true;

      let crab = null;

      let p1 = Promise.resolve(true);

      if (typeof Crab === 'undefined') {
        const script = document.createElement('script');
        script.src = `/joxi/js/crab/sdk/launch.v1.js?t=${Date.now()}`;
        document.body.appendChild(script);
        p1 = new Promise((resolve) => {
          script.onload = () => resolve(true);
        });
      }

      let p2 = _banner.collection({ out: 'images,text', place: 'cash_crab' });

      let p3 = _game.launch({ code: 'cash-crab', mode: 'real' });

      Promise.all([p1, p2, p3]).then((values) => {
        scope.preloader = false;

        let launch = values[2].result;
        let prizes = mapPrizes(values[1].result);

        const launch_options = Object.assign(
          {
            view: 'game',
            brand: _config.siteName,
            locale: _config.currentLang,
            prizes: prizes,
            brandProfile: _user.profile,
            brandFeature: _config.feature,
            onClickTerms: () => {
              let url = $state.href(_state.get('terms').name);
              window.open(url, '_blank');
            },
            onClickLoseBtn: () => {
              _state.goto('home');
            },
            onClickBetModeBtn: () => {
              _state.goto('cash_crab');
            },
            onClickDepositBtn: () => {
              _popup.open({ name: 'cashbox' });
            },
            onRoundEnd: () => {
              _account.info({ nocache: true });
            },
          },
          launch.launch_options
        );

        crab = new Crab(element[0], launch_options);
      });

      function mapPrizes(p) {
        return p.map((p) => ({
          alias: p.text[0],
          image: `${_config.apiUrl}${p.images.list[0]}`,
          name: p.text[1],
          amount: p.text[2],
          description: p.text[3],
          buttonText: p.text[4],
          onClick: () => {
            _state.goto(p.text[5]);
          },
        }));
      }
    };

    return { link };
  }

  app.directive(directive.name, controller);
})();
