import page from '../../../service/domain/page.js';
(function () {
  'use strict';

  controller.$inject = ['$scope', '$state', 'page', '$sce'];

  function controller($scope, $state, _page, $sce) {
    $scope.page = '';
    $scope.preloader = false;

    function getInfoPage(name) {
      $scope.preloader = true;
      _page
        .item({ name })
        .then((answer) => {
          $scope.page = $sce.trustAsHtml(answer.result.content);
        })
        .catch(() => {})
        .finally(() => {
          $scope.preloader = false;
        });
    }

    this.$onChanges = function () {
      const name = this.name || $state.params.name || '';

      if (name !== '') {
        getInfoPage(name);
      }
    };
  }

  new Controller('infoPage', controller, {
    name: '<',
  });
})();

[1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
  new Batman(`batmanInfoPage${i}`, 'infoPage');
});
