import http from '../../service/domain/http.js';
import { Subject } from 'rxjs'

(function () {
  'use strict';

  app.factory('seo', controller);

  controller.$inject = ['http', '$q'];

  function controller(_http, $q) {
    return {
      item(params) {
        const defer = $q.defer(),
          alias = 'seoItem',
          config = {
            params,
            cache: true,
            meta: {
              cache: true,
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer);
          }
        );

        return defer.promise;
      },

      meta$: new Subject(),
    };
  }
})();
