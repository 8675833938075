import user from "../../service/domain/user.js"
(function () {
  'use strict';

  controller.$inject = ['user'];

  function controller(_user) {
    const formDataSchema = {
      limits_deposit_day: createSchema(
        {
          type: 'day',
          amount: 1000,
          group: 'deposit',
        },
        'array'
      ),

      limits_deposit_week: createSchema(
        {
          type: 'week',
          amount: 1000,
          group: 'deposit',
        },
        'array'
      ),

      limits_deposit_month: createSchema(
        {
          type: 'month',
          amount: 1000,
          group: 'deposit',
        },
        'array'
      ),

      limits_deposit_day_sweden: createSchema(
        {
          type: 'day',
          amount: _user.profile.limits?.deposit?.day?.amount || '',
          group: 'deposit',
        },
        'array'
      ),

      limits_deposit_week_sweden: createSchema(
        {
          type: 'week',
          amount: _user.profile.limits?.deposit?.week?.amount || '',
          group: 'deposit',
        },
        'array'
      ),

      limits_deposit_month_sweden: createSchema(
        {
          type: 'month',
          amount: _user.profile.limits?.deposit?.month?.amount || '',
          group: 'deposit',
        },
        'array'
      ),

      accept_terms_and_conditions: createSchema(
        {
          value: true,
        },
        'string'
      ),

      accept_migration_gdpr: createSchema(
        {
          value: true,
        },
        'string'
      ),

      accept_deposit_limit_change: createSchema(
        {
          value: true,
        },
        'string'
      ),

      accept_migration_romix_to_maltix: createSchema(
        {
          value: true,
        },
        'string'
      ),

      profile_pep: createSchema(
        {
          pep: true,
        },
        'object'
      ),

      profile_birth_place: createSchema(
        {
          birth_place: '',
        },
        'object'
      ),

      profile_nationality: createSchema(
        {
          nationality: '',
        },
        'object'
      ),

      profile_birth_country: createSchema(
        {
          birth_name: '',
          birth_country: '',
        },
        'object'
      ),

      limits_deposit: createSchema(
        {
          group: 'deposit',
          type: '',
          amount: '',
        },
        'object'
      ),
    };

    const apiSchema = {
      limits_deposit: 'update_limit',
      limits_deposit_day: 'update_multi_limits',
      limits_deposit_week: 'update_multi_limits',
      limits_deposit_month: 'update_multi_limits',
      limits_deposit_day_sweden: 'update_multi_limits',
      limits_deposit_week_sweden: 'update_multi_limits',
      limits_deposit_month_sweden: 'update_multi_limits',
      accept_terms_and_conditions: 'accept_terms_and_conditions',
      accept_migration_gdpr: 'accept_migration_gdpr',
      accept_deposit_limit_change: 'accept_deposit_limit_change',
      accept_migration_romix_to_maltix: 'accept_migration_romix_to_maltix',
      profile_pep: 'update',
      profile_birth_place: 'update',
      profile_nationality: 'update',
      profile_birth_country: 'update',
    };

    function createSchema(obj, structure) {
      return Object.defineProperty(obj, '_structure', { value: structure });
    }

    function generateFormData() {
      const missedFields = _user.profile.requiredFields || [];
      const formData = {};

      missedFields.forEach((field) => {
        const key = apiSchema[field];

        if (!key) return;

        if (formData[key]) {
          switch (formDataSchema[field]._structure) {
            case 'array':
              formData[key].push({ ...formDataSchema[field] });
              break;
            case 'object':
              Object.assign(formData[key], formDataSchema[field]);
              break;

            case 'string':
              formData[key] = formDataSchema[field].value;
              break;

            default:
              break;
          }
        } else {
          switch (formDataSchema[field]._structure) {
            case 'array':
              formData[key] = [{ ...formDataSchema[field] }];
              break;
            case 'object':
              formData[key] = {};
              Object.assign(formData[key], formDataSchema[field]);
              break;

            case 'string':
              formData[key] = formDataSchema[field].value;
              break;

            default:
              break;
          }
        }
      });

      return formData;
    }

    return {
      getFormData() {
        return generateFormData();
      },
    };
  }

  app.factory('dynamicForm', controller);
})();
