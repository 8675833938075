import depositAmount from '../service/mask/deposit-amount.js';
import withdrawAmount from '../service/mask/withdraw-amount.js';
import lpAmount from '../service/mask/lp-amount.js';
(function () {
  controller.$inject = ['depositAmount', 'withdrawAmount', 'lpAmount'];

  function controller(_depositAmount, _withdrawAmount, _lpAmount) {
    const link = (scope, element, attrs, ngModel) => {
      attrs.$set('type', 'text');
      const type = attrs.maskAs || null;
      const mask = {};

      switch (type) {
        case 'depositAmount':
          mask.type = type;
          mask.init = _depositAmount.init;
          mask.validator = _depositAmount.validator;
          mask.customValidators = _depositAmount.customValidators;
          break;

        case 'withdrawAmount':
          mask.type = type;
          mask.init = _withdrawAmount.init;
          mask.validator = _withdrawAmount.validator;
          mask.customValidators = _withdrawAmount.customValidators;
          break;

        case 'lpAmount':
          mask.type = type;
          mask.init = _lpAmount.init;
          mask.validator = _lpAmount.validator;
          mask.customValidators = _lpAmount.customValidators;
          break;

        default:
          mask.type = null;
          break;
      }

      if (mask.type) {
        mask.instance = mask.init(element, ngModel);

        if (typeof mask.customValidators === 'function') mask.customValidators(scope, element, attrs, ngModel);

        ngModel.$validators[mask.type] = (modelValue, viewValue) => {
          const value = modelValue || viewValue;
          return value ? mask.validator(mask.instance) : true;
        };
      }
    };

    return { restrict: 'A', require: '?ngModel', link };
  }

  app.directive('maskAs', controller);
})();
