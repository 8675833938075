import game from '../service/domain/game.js';
(function () {
  'use strict';

  const directive = { name: 'showGameLinkByLocation' };

  controller.$inject = ['game'];

  function controller(_game) {
    function link($scope, $element, $attrs, $ctrl, $transclude) {
      const gameName = $attrs[directive.name] || '';
      _game
        .item({ name: gameName })
        .then((gameItem) => {
          if (gameItem.result) {
            $transclude($scope, (clone) => {
              $element[0].parentNode.insertBefore(clone[0], $element[0].nextSibling);
            });
          }
        })
        .catch((error) => {})
        .finally(() => {});
    }

    return {
      restrict: 'A',
      link,
      transclude: 'element',
    };
  }

  app.directive(directive.name, controller);
})();
