import system$ from "../../../service/rx/system$.js"
import user from "../../../service/domain/user.js"
import splash$ from "../../../service/rx/splash$.js"
import config from "../../../service/configs/config.js"
import { Subject } from 'rxjs';
import { take, filter, takeUntil } from 'rxjs/operators';

(function () {
  'use strict';

  const name = 'lindaLiveAgent';

  controller.$inject = ['system$', 'user', '$location', 'splash$', 'config'];

  function controller(_system$, _user, $location, _splash$, _config) {
    const link = (scope, element, attrs) => {
      const destroy$ = new Subject();

      const config = scope.$eval(attrs[name]) || _config.liveAgent;
      const script = document.createElement('script');
      const target = document.scripts[document.scripts.length - 1];
      const src = $_CDN.liveAgent?.sdk;
      let subscription;

      script.id = config.sid;
      script.async = true;
      script.src = src;
      script.onload = onload;

      _splash$
        .pipe(
          filter((m) => m === 'loaded'),
          take(1),
          takeUntil(destroy$)
        )
        .subscribe((i) => {
          target.parentElement.insertBefore(script, target.nextSibling);
        });

      function onload() {
        if (_user.status) {
          setProfileData(_user.profile);
        } else {
          _system$
            .pipe(filter((message) => message.action === 'login'))
            .subscribe((message) => {
              setProfileData(_user.profile);
            });
        }
        const container = document.createElement('div');
        element[0].appendChild(container);
        let button = LiveAgent.createButton(config.bid, container);

        button.onOnline = function () {
          _system$
            .pipe(filter((message) => message.action === 'open chat'))
            .subscribe((message) => {
              button.onClick();
              // Fixes multiple open on wazamba but breaks rabona
              // button.chat.startChat(button.chat.url + '&cwt=onlineform_mobile')
            });

          if ($location.hash() === 'chat') {
            $location.hash('');
            button.onClick();
            // button.chat.startChat(button.chat.url + '&cwt=onlineform_mobile')
          }
        };
      }

      function setProfileData(user) {
        const email = user.email_guid || user.email;
        LiveAgent.setUserDetails(email, user.profile.name, user.profile.surname, user.phone);
        LiveAgent.addTicketField('Username', `${user.login}`);
      }

      scope.$onDestroy = () => {
        destroy$.next();
        destroy$.unsubscribe();
      };
    };

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(name, controller);
})();
