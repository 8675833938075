import '../domain/http.js';
import '../rx/system$.js';
import '../domain/popup.js';
import '../domain/user.js';
import { filter } from "rxjs/operators";

(function () {
  'use strict';

  controller.$inject = ['http', '$q', 'system$', 'popup', 'user'];

  const notificationTypes = ['stage_one_teaser', 'stage_two_starting_teaser', 'stage_two_finishing_teaser'];

  function controller(_http, $q, _system$, _popup, _user) {
    return {
      init() {
        _system$
          .pipe(filter((message) => message.action === 'login'))
          .subscribe(() => {
            this.data().then(({ result }) => {
              if (
                _user.profile.sessions_info.is_first_session === false &&
                notificationTypes.includes(result.notification_type)
              ) {
                _popup.open({ name: 'ferrari-campaign', content: result.notification_type });
              }
            });
          });
      },
      data() {
        const alias = 'dataCarPromo';
        const defer = $q.defer();
        const config = {
          meta: {
            type: 'api',
            alias,
          },
        };

        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          },
        );

        return defer.promise;
      },

      leaderBoard(options) {
        const alias = 'leaderBoardCarPromo';
        const defer = $q.defer();
        const config = {
          params: options,
          meta: {
            type: 'api',
            alias,
          },
        };

        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          },
        );

        return defer.promise;
      },
    };
  }

  app.factory('carPromo', controller);
})();
