import history from '../../../service/domain/history.js';
import ws$ from '../../../service/rx/ws$.js';
import { filter } from 'rxjs/operators';
(function () {
  'use strict';

  const component = { name: 'lindaWidgetEventList' };

  controller.$inject = ['$scope', 'history', 'ws$'];

  function controller($scope, _history, _ws$) {
    $scope.collection = [];

    function prepare(arr) {
      return arr.map((item) => {
        return {
          avatar: item.avatar || 'nopic',
          level: item.level,
          login: item.login,
          type: item.type === 'new_bonus' && item.bonus.type === 'freespin' ? 'new_freespin' : item.type,
          data: item.bonus || {},
          sum: item.sum || null,
          currency: item.currency || null,
          game: item.game || {},
          achievement: item.achievement || {},
        };
      });
    }

    let events = [
      '-',
      //            'new_win',
      //            'new_bonus'
    ];

    this.$onChanges = () => {
      events = [].concat(events, this.events);

      $scope.preloader = true;
      _history
        .collection({ params: {}, endpoint: 'event' })
        .then(
          (answer) => {
            if (answer.result.length > 0) {
              $scope.collection = prepare(answer.result).filter((item) => events.indexOf(item.type) >= 1);
            }
          },
          () => {}
        )
        .finally(() => {
          $scope.preloader = false;
        });
    };

    _ws$
      .pipe(filter((item) => events.indexOf(item.event) >= 1))
      .subscribe((message) => {
        $scope.collection.push({
          avatar: message.data.avatar || 'nopic',
          level: message.data.level,
          login: message.data.login,
          type:
            message.event === 'new_bonus' && message.data.bonus.type === 'freespin' ? 'new_freespin' : message.event,
          data: message.data.bonus || {},
          sum: message.data.sum || null,
          currency: message.data.currency || null,
          game: message.data.game || {},
        });
      });
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
    bindings: {
      events: '<',
    },
  });
})();
