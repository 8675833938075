import config from '../../service/configs/config.js';
(function () {
  'use strict';

  controller.$inject = ['$q', 'config'];

  function controller($q, _config) {
    window.FirebasePlugin = window.FirebasePlugin || {};

    return {
      onNotificationOpen(onSuccess, onError) {
        window.FirebasePlugin.onNotificationOpen(
          function (notification) {
            if (typeof onSuccess === 'function') {
              onSuccess(notification);
            }
          },
          function (error) {
            if (typeof onError === 'function') {
              onError(error);
            }
          }
        );
      },
      getToken() {
        const defer = $q.defer();
        window.FirebasePlugin.getToken(
          function (token) {
            defer.resolve(token);
          },
          function (error) {
            defer.reject(error);
          }
        );
        return defer.promise;
      },
      onTokenRefresh() {
        const defer = $q.defer();
        window.FirebasePlugin.onTokenRefresh(
          function (token) {
            defer.resolve(token);
          },
          function (error) {
            defer.reject(error);
          }
        );
        return defer.promise;
      },
      grantPermission() {
        if (_config.mobileConfig.platform === 'ios') {
          window.FirebasePlugin.grantPermission();
        }
      },
      hasPermission() {
        const defer = $q.defer();
        if (typeof window.FirebasePlugin.hasPermission === 'function') {
          window.FirebasePlugin.hasPermission(function (data) {
            defer.resolve(data.isEnabled);
          });
        } else {
          defer.resolve(false);
        }
        return defer.promise;
      },
      setBadgeNumber(number) {
        window.FirebasePlugin.setBadgeNumber(number);
      },
      getBadgeNumber() {
        const defer = $q.defer();
        window.FirebasePlugin.getBadgeNumber(function (number) {
          defer.resolve(number);
        });
        return defer.promise;
      },
      subscribe(topic) {
        window.FirebasePlugin.subscribe(topic);
      },
      unsubscribe(topic) {
        window.FirebasePlugin.unsubscribe(topic);
      },
      unregister() {
        window.FirebasePlugin.unregister();
      },
      logEvent(name, values) {
        window.FirebasePlugin.logEvent(name, values);
      },
      setScreenName(screen) {
        window.FirebasePlugin.setScreenName(screen);
      },
      setUserId(id) {
        window.FirebasePlugin.setUserId(id);
      },
      setUserProperty(name, value) {
        window.FirebasePlugin.setUserProperty(name, value);
      },
    };
  }

  app.factory('firebasePlugin', controller);
})();
