import http from "../../service/domain/http.js"
import getUrlString from "../../service/utils/get-url-string.js"
(function () {
  'use strict';

  const getYesterday = () => {
    const now = new Date();
    now.setDate(now.getDate() - 1);
    return now;
  };

  controller.$inject = ['$q', 'http', '$httpParamSerializer', 'getUrlString'];

  function controller($q, _http, $httpParamSerializer, getUrlString) {
    return {
      getFormattedYesterday() {
        const yesterday = getYesterday();

        const year = yesterday.getFullYear();
        const month = yesterday.getMonth() + 1;
        const day = yesterday.getDate();

        return year + '-' + ('0' + month).slice(-2) + '-' + ('0' + day).slice(-2);
      },
      getFormattedToday() {
        return (
          new Date().getFullYear() +
          '-' +
          ('0' + (new Date().getMonth() + 1)).slice(-2) +
          '-' +
          ('0' + new Date().getDate()).slice(-2)
        );
      },

      filterData: {},

      getFilter() {
        const defer = $q.defer();

        this.filterData = {
          type: [
            { name: 'spin', maxDays: 1 },
            { name: 'deposit', maxDays: 0 },
            { name: 'withdraw', maxDays: 0 },
            { name: 'bonus_deposit', maxDays: 0 },
            { name: 'bonus_withdraw', maxDays: 0 },
            { name: 'bonus_release', maxDays: 0 },
            { name: 'cancel_withdraw', maxDays: 0 },
          ],
          status: [
            { name: 'deposit_pending', type: 'deposit' },
            { name: 'deposit_complete', type: 'deposit' },
            { name: 'deposit_cancel', type: 'deposit' },
            { name: 'deposit_decline', type: 'deposit' },
            { name: 'deposit_error', type: 'deposit' },
            { name: 'withdraw_pending', type: 'withdraw' },
            { name: 'withdraw_complete', type: 'withdraw' },
            { name: 'withdraw_cancel', type: 'withdraw' },
            { name: 'withdraw_decline', type: 'withdraw' },
            { name: 'withdraw_error', type: 'withdraw' },
            { name: 'bonus_deposit_pending', type: 'bonus_deposit' },
            { name: 'bonus_deposit_complete', type: 'bonus_deposit' },
            { name: 'bonus_deposit_cancel', type: 'bonus_deposit' },
            { name: 'bonus_deposit_decline', type: 'bonus_deposit' },
            { name: 'bonus_deposit_error', type: 'bonus_deposit' },
            { name: 'bonus_withdraw_pending', type: 'bonus_withdraw' },
            { name: 'bonus_withdraw_complete', type: 'bonus_withdraw' },
            { name: 'bonus_withdraw_cancel', type: 'bonus_withdraw' },
            { name: 'bonus_withdraw_decline', type: 'bonus_withdraw' },
            { name: 'bonus_withdraw_error', type: 'bonus_withdraw' },
            { name: 'bonus_release_pending', type: 'bonus_release' },
            { name: 'bonus_release_complete', type: 'bonus_release' },
            { name: 'bonus_release_cancel', type: 'bonus_release' },
            { name: 'bonus_release_decline', type: 'bonus_release' },
            { name: 'bonus_release_error', type: 'bonus_release' },
            { name: 'cancel_withdraw_pending', type: 'cancel_withdraw' },
            { name: 'cancel_withdraw_complete', type: 'cancel_withdraw' },
            { name: 'cancel_withdraw_cancel', type: 'cancel_withdraw' },
            { name: 'cancel_withdraw_decline', type: 'cancel_withdraw' },
            { name: 'cancel_withdraw_error', type: 'cancel_withdraw' },
          ],
        };

        defer.resolve({
          result: this.filterData,
        });
        return defer.promise;

        /*
                const defer = $q.defer(),
                    alias = 'historyFilter', //todo add api alias
                    config = {
                        params: {},
                        cache: true,
                        meta: {
                            type: 'api',
                            alias
                        }
                    };

                _http.get( alias, config ).then(
                    answer => { defer.resolve( answer.data ) },
                    answer => { defer.reject( answer.data ) }
                );

                return defer.promise;
                */
      },

      balanceFlow(options) {
        const statuses = options.status.length ? '&' + getUrlString({ status: options.status }) : '';
        const types = options.type.length ? '&' + getUrlString({ type: options.type }) : '';

        delete options.type;
        delete options.status;

        const date = $httpParamSerializer(options);

        const url = `?${date}${statuses}${types}`;
        const defer = $q.defer(),
          alias = {
            name: 'historyBalanceFlow',
            param: url,
          },
          // 'historyBalanceFlow',
          config = {
            //params: options,
            cache: false,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject();
          }
        );

        return defer.promise;
      },

      collection(options) {
        options.params.count = 1000;

        const defer = $q.defer(),
          alias = { name: 'history', param: options.endpoint },
          config = {
            params: options.params,
            cache: false,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          function (answer) {
            defer.resolve(answer.data);
          },
          function (answer) {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },
    };
  }

  app.factory('history', controller);
})();
