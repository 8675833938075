import http from "../../service/domain/http.js"
import user from "../../service/domain/user.js"
import state from "../../service/domain/state.js"
import remembered from "../../service/domain/remembered.js"
import system$ from "../../service/rx/system$.js"
import { BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

(function () {
  'use strict';

  controller.$inject = ['http', 'user', 'state', 'remembered', 'system$'];

  function controller(_http, _user, _state, _remembered, _system$) {
    let isInitialized = false;
    let maxCount = 100;

    const games$ = new BehaviorSubject([]);

    const removeGame = (game) => {
      games$.next(games$.getValue().filter(({ id }) => id !== game.id));
    };

    const addGame = (game) => {
      games$.next([...games$.getValue(), game]);
    };

    const toggle = (game) => {
      const options = { id: game.id };
      const alias = 'gameToggleFav';
      const config = {
        cache: false,
        meta: {
          type: 'api',
          alias,
        },
      };

      if (!_user.status) {
        const rememberedLogin = _remembered.login;

        if (!rememberedLogin) {
          return _state.goto('registration');
        }

        return _state.goto('login');
      }

      game.favorites ? removeGame(game) : addGame(game);

      _http.post(alias, options, config).catch(() => {
        game.favorites ? addGame(game) : removeGame(game);
      });
    };

    const requestGames = () => {
      isInitialized = true;

      const alias = 'gameList';
      const config = {
        params: {
          favorites: true,
          count: maxCount,
        },
        cache: false,
        meta: {
          type: 'api',
          alias,
        },
      };

      _http
        .get(alias, config)
        .then(({ data: { result } }) => games$.next(result))
        .catch(({ data }) => console.error(data));
    };

    const collection = (count) => {
      if ((!isInitialized || count > maxCount) && _user.status) {
        requestGames();
      }

      if (count > maxCount) {
        count = maxCount;
      }

      return games$.pipe(map((games) => games.slice(0, count ?? maxCount)));
    };

    const shouldSetFavorite = (games, game) => {
      if (!game.id) return;

      return games.some(({ id }) => id === game.id) && !game.favorites;
    };

    const shouldRemoveFavorite = (games, game) => {
      if (!game.id) return;

      return !games.some(({ id }) => id === game.id) && game.favorites;
    };

    _system$
      .pipe(filter((event) => event.action === 'logout'))
      .subscribe(() => {
        isInitialized = false;
      });

    return {
      collection,
      toggle,
      shouldSetFavorite,
      shouldRemoveFavorite,
    };
  }

  app.factory('favorites', controller);
})();
