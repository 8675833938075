import config from '../service/configs/config.js';
(function () {
  'use strict';

  const directive = { name: 'copyToClipboard' };

  controller.$inject = ['config'];

  function controller(_config) {
    const link = (scope, element, attrs) => {
      attrs.$observe(directive.name, (value) => {
        if (!value) {
          return false;
        }

        element.on('click', () => {
          let textArea = document.createElement('textArea');
          textArea.value = value;
          document.body.appendChild(textArea);

          if (_config.ios) {
            textArea.readOnly = true;
            textArea.contentEditable = true;

            let range = document.createRange();
            range.selectNodeContents(textArea);
            let selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            textArea.setSelectionRange(0, 999999);
          } else {
            textArea.select();
          }

          document.execCommand('copy');
          document.body.removeChild(textArea);
        });
      });
    };

    return {
      link,
    };
  }

  app.directive(directive.name, controller);
})();
