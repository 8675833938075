import zipcodeMask from '../service/utils/zipcode-mask.js';
(function () {
  const directive = { name: 'maskZipcode' };

  controller.$inject = ['zipcodeMask'];
  function controller(_zipcodeMask) {
    let zipcodeMasks = [];

    const link = (scope, element, attrs, ngModel) => {
      let inputMask = new IMask(element[0], {
        mask: /./,
      });

      inputMask.on('accept', () => ngModel.$setViewValue(inputMask.value || ''));

      ngModel.$parsers.push((value) => value);

      ngModel.$formatters.push((modelValue) => {
        if (!modelValue) return '';
        inputMask.value = modelValue;
        return modelValue;
      });

      function getZipInfo() {
        return _zipcodeMask
          .list()
          .then((answer) => {
            zipcodeMasks = answer;
          })
          .catch(() => {})
          .finally(() => {});
      }

      function addValidator(country) {
        if (!country) return;
        const defaultCountryMask = {
          mask: /^[a-zA-Z0-9-]+$/,
          length: 100,
        };

        const countryMask = zipcodeMasks.find((mask) => mask.country === country) || defaultCountryMask;
        element[0].maxLength = countryMask.length;
        inputMask.updateOptions({
          mask: countryMask.mask,
        });
      }

      function init() {
        if (zipcodeMasks.length) {
          addValidator(scope.formData.country);
          return;
        }
        getZipInfo().then(() => {
          addValidator(scope.formData.country);
        });
      }

      init();

      scope.$watch('formData.country', (newVal) => {
        if (zipcodeMasks.length) {
          addValidator(newVal);
        }
      });

      scope.$onDestroy = () => {
        inputMask.destroy();
      };
    };

    return { restrict: 'A', require: '?ngModel', link };
  }

  app.directive(directive.name, controller);
})();
