import holiday from '../../service/periodicals/holiday.js';
import config from '../../service/configs/config.js';
(function () {
  'use strict';

  const component = { name: 'lindaPromoWrapper' };

  controller.$inject = ['$scope', '$state', 'holiday', 'config', '$element', '$compile'];

  function controller($scope, $state, _holiday, _config, $element, $compile) {
    let componentName = '';

    $scope.name = null;

    this.$onInit = () => {
      $scope.name = $state.params.name;
      const componentName = `${$scope.name}-promo${_config.mobile ? '-mobile' : ''}`;

      let el = document.createElement(componentName);
      let compileEl = $compile(el)($scope)[0];
      $element[0].appendChild(compileEl);
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
