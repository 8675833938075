import popup from '../../service/domain/popup.js';
(function () {
  'use strict';

  const directive = { name: 'popupCloseTap' };

  controller.$inject = ['popup'];

  function controller(_popup) {
    function link(scope, element, attrs) {
      let tapping = false;

      element.on('touchstart', function (e) {
        tapping = true;
      });

      element.on('touchmove', function (e) {
        tapping = false;
      });

      element.on('touchend', function () {
        if (!tapping) {
          return false;
        }

        _popup.close();
      });
    }

    return {
      restrict: 'A',
      link: link,
    };
  }

  app.directive(directive.name, controller);
})();
