import game from '../../service/domain/game.js';
import system$ from '../../service/rx/system$.js';
import { filter } from 'rxjs/operators'

(function () {
  'use strict';

  const directive = { name: 'lindaIsFavourite' };

  controller.$inject = ['game', 'system$'];

  function controller(_game, _system$) {
    function link(scope, element, attrs) {
      let alias;
      const className = attrs[`${directive.name}ClassName`] || 'linda-is-favourite';

      const stopObserving = attrs.$observe(directive.name, (newGame) => {
        if (newGame) {
          alias = newGame;
          element.toggleClass(className, _game.favourite.check(alias));

          _system$
            .pipe(filter((m) => m.action === 'renew favourite'))
            .subscribe((m) => {
              element.toggleClass(className, _game.favourite.check(alias));
            });

          stopObserving();
        }
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
