import icon from "../../../service/domain/icon.js"
(function () {
  controller.$inject = ['$scope', 'icon'];

  function controller($scope, _icon) {
    $scope.collection = [];
    $scope.preloader = false;

    function getCollection(o) {
      $scope.preloader = true;
      _icon
        .collection(o)
        .then((a) => {
          $scope.collection = a.result;
        })
        .catch((e) => {})
        .finally(() => {
          $scope.preloader = false;
        });
    }

    this.$onChanges = () => {
      let config = {};
      config.category = this.category;
      config.count = this.count || 25;
      getCollection(config);
    };
  }

  new Controller('iconBox', controller, { category: '<', count: '<' });
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanIconBox${i}`, 'iconBox');
  });
})();
