import popup from "../service/domain/popup.js"
import contentBuffer from "../service/domain/content-buffer.js"
import user from "../service/domain/user.js"
(function () {
  'use strict';

  controller.$inject = ['popup', 'contentBuffer', 'user'];

  function controller(_popup, _contentBuffer, _user) {
    function link(scope, element, attrs) {
      element.on('click', function () {
        const popupValue = scope.$eval(attrs.popupOpen) || { name: attrs.popupOpen };

        if (popupValue.private === true && !_user.status) {
          _popup.open({
            name: 'login',
            cb() {
              _popup.open(popupValue);
            },
          });
          return;
        }

        _popup.open(popupValue);
      });
    }

    return {
      restrict: 'A',
      link: link,
    };
  }

  app.directive('popupOpen', controller);
})();
