import user$ from '../../service/rx/user$.js';
import config from '../../service/configs/config.js';
import bonus$ from '../../service/rx/bonus$.js';
import system$ from '../../service/rx/system$.js';
import model from '../../service/domain/model.js';
(function () {
  'use strict';

  controller.$inject = ['user$', 'config', 'bonus$', 'system$', 'model'];

  function controller(_user$, _config, _bonus$, _system$, _model) {
    let _profile = _model.profile;
    let _bonus = [];
    let _token = window.localStorage.getItem('token') || null;
    let _status = null;
    let _activeBonus = {};
    let _casinoBonus = [];
    let _sportBonus = [];
    const _limits = {
      time: false,
      deposit: false,
      loose: false,
      wager: false,
      session: false,
    };
    let _balance = {};
    const user = {};

    Object.defineProperty(user, 'status', {
      set(value) {
        let statusChanged = false;
        if (!_status && value === true) {
          _system$.next({ action: 'login' });
        }

        if (_status === true && value === false) {
          _system$.next({ action: 'logout' });
        }

        if (_status !== value) {
          statusChanged = true;
        }

        _status = value;

        if (statusChanged) {
          _system$.next({ action: 'statusChanged' });
        }

        _config.sessionStatus = value;
      },
      get() {
        return _status;
      },
    });

    user.status = _token === null ? false : null;

    Object.defineProperty(user, 'sessionToken', {
      set(token) {
        _token = token;
        window.localStorage.setItem('token', token);
      },
      get() {
        _token = window.localStorage.getItem('token') || null;
        return _token;
      },
    });

    Object.defineProperty(user, 'limits', {
      set(data) {
        if (data.login_time && data.login_time.day && data.login_time.day.amount) _limits.time = true;
        if (data.login_time && data.login_time.week && data.login_time.week.amount) _limits.time = true;
        if (data.login_time && data.login_time.month && data.login_time.month.amount) _limits.time = true;
      },
      get() {
        return _limits;
      },
    });

    Object.defineProperty(user, 'profile', {
      set(data) {
        if (data.login) {
          data.diffUserAndServerTime = Date.now() - data.server_time_ts; // diff betwen time of user and time of server(in ms)
          _profile = data;
          user.status = true;
          user.sessionToken = btoa(data.prefix + data.login.toUpperCase() + ':' + data.session_id);
          user.limits = data.limits || {};
          _model.profile = data;
        } else {
          _profile = _model.profile;
          user.status = false;
          user.sessionToken = null;
          user.balance = {};
        }

        _user$.next(user);
      },
      get() {
        return _profile;
      },
    });

    Object.defineProperty(user, 'activeBonus', {
      set(data) {
        _activeBonus = data || {};
      },
      get() {
        return _activeBonus;
      },
    });

    Object.defineProperty(user, 'casinoBonus', {
      set(data) {
        _casinoBonus = data || [];
      },
      get() {
        return _casinoBonus;
      },
    });

    Object.defineProperty(user, 'sportBonus', {
      set(data) {
        _sportBonus = data || [];
      },
      get() {
        return _sportBonus;
      },
    });

    Object.defineProperty(user, 'bonus', {
      set(data) {
        user.activeBonus = {};
        user.casinoBonus = [];
        user.sportBonus = [];

        _bonus = data.filter(
          // TODO this is kostbllb
          // we dont do bonus filter now
          // we will rewrite it in Q4
          (bonus) => !bonus.is_promo_bonus || !bonus.is_retention_bonus || !!bonus.retention_bonus_rules_fulfilled,
        );
        if (_bonus.length) {
          user.activeBonus = _bonus.find((bonus) => bonus.status === 'active');

          user.casinoBonus = _bonus.filter((bonus) => bonus.type === 'casino' || bonus.type === 'freespin');
          user.sportBonus = _bonus.filter((bonus) => bonus.type === 'sport');
        }

        _user$.next(user);
        _bonus$.next(data);
      },

      get() {
        return _bonus;
      },
    });

    Object.defineProperty(user, 'balance', {
      set(data) {
        _balance = data;
      },
      get() {
        return _balance;
      },
    });

    return user;
  }

  app.factory('user', controller);
})();
