import analytics from '../service/analytics/analytics.js';
(function () {
  'use strict';

  // this directive used for watching all inputs in the form and send formData to backabd after blur event

  const directive = { name: 'bindAnalyticsClick' };

  controller.$inject = ['analytics'];

  function controller(analytics) {
    function link(scope, element, attrs) {
      scope.track = analytics.send.bind(analytics);
      scope.$parent.track = analytics.send.bind(analytics);

      element[0].onclick = () => {
        const params = scope.$parent.$eval(attrs[directive.name]) || {};
        analytics.send(...params);
      };
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
