import bank from '../../service/domain/bank.js';
import helper from '../../service/helper.js';
import payment from '../../service/domain/payment.js';
import config from '../../service/configs/config.js';
import state from '../../service/domain/state.js';
import modal from '../../service/domain/modal.js';
import entercashConfig from '../../service/configs/entercash.js';
import popup from '../../service/domain/popup.js';
(function () {
  'use strict';

  const component = { name: 'swedenBankidIframe' };

  controller.$inject = [
    '$scope',
    'bank',
    'helper',
    'payment',
    'config',
    '$timeout',
    'state',
    'modal',
    'entercash.config',
    '$state',
    'popup',
  ];

  function controller(
    $scope,
    _bank,
    _helper,
    _payment,
    _config,
    $timeout,
    _state,
    _modal,
    _entercash_config,
    $state,
    _popup
  ) {
    $scope.preloader = false;

    /**
     *       url fo bank id iframe
     **/

    $scope.url = '';

    const flag = {
      amount: null,
      deposit: false,
    };

    const o = {
      paymethod: 'entercash',
      token: null,
    };

    const messageHandler = {
      AUTHENTICATE() {},
      SELECT_BANK() {
        $timeout(() => {
          $scope.preloader = false;
        }, 0);
      },
      CANCEL() {},
      OK() {
        $scope.url = '/preloader.html';
        $scope.preloader = true;
        _bank
          .auth(o)
          .then((a) => {
            makeDeposit();
          })
          .catch((e) => {
            if (e.messages[0].code === 5051) {
              const params = Object.assign({}, o, { profile: e.messages[0].data.person });
              _modal.open('registration', params).then(
                (a) => {
                  makeDeposit();
                },
                (a) => {
                  console.error('false');
                }
              );
            }
          })
          .finally(() => {
            $scope.preloader = false;
          });
      },
    };

    function makeDeposit() {
      if (flag.deposit) {
        const c = {
          amount: flag.amount,
          paymethod: 'entercash',
          instantplay: true, // flag for backend
        };

        _payment
          .deposit(c)
          .then((a) => {
            $scope.url = a.result.url;
          })
          .catch((e) => {})
          .finally(() => {
            $scope.preloader = false;
          });
      } else {
        _popup.close();
      }
    }

    function gotMessage(m) {
      if (m.data.type === 'ec:state:change' && typeof messageHandler[m.data.state] === 'function')
        messageHandler[m.data.state]();
    }

    function init() {
      $scope.preloader = true;
      _bank
        .init({ paymethod: 'entercash', country: 'SE' })
        .then((a) => {
          $scope.url = a.result.url;
          o.token = a.result.token;
        })
        .catch((e) => {})
        .finally(() => {
          $scope.preloader = false;
        });
    }

    this.$onChanges = () => {
      flag.amount = this.ngModel || null;
      if (flag.amount !== null) {
        flag.deposit = true;
      }
      init();
    };

    this.$onInit = () => {
      window.addEventListener('message', gotMessage);
    };

    this.$onDestroy = () => {
      window.removeEventListener('message', gotMessage);
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
    bindings: {
      ngModel: '<',
    },
  });
})();
