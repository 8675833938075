import jackpot from "../../../service/domain/jackpot.js"
(function () {
  controller.$inject = ['$scope', 'jackpot', '$interval'];

  function controller($scope, _jackpot, $interval) {
    $scope.jackpot = null;
    $scope.currency = 'EUR';

    $interval(() => {
      if ($scope.jackpot) {
        $scope.jackpot = (($scope.jackpot * 100 + 1) / 100).toFixed(2);
      }
    }, 1000);

    this.$onChanges = () => {
      const currencyIsoCode = this.currency && this.currency.length ? `${this.currency.toUpperCase()}` : 'EUR';

      $scope.preloader = true;
      _jackpot
        .collection({ currencyIsoCode })
        .then((a) => {
          $scope.jackpot = a.result.latest;
        })
        .catch((e) => {})
        .finally(() => {
          $scope.preloader = false;
        });
    };
  }

  new Controller('widgetLatestJackpot', controller, {
    currency: '<',
  });
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanWidgetLatestJackpot${i}`, 'widgetLatestJackpot');
  });
})();
