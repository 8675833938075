import http from '../../service/domain/http.js';
import ws$ from '../../service/rx/ws$.js';

(function () {
  'use strict';

  controller.$inject = ['$q', 'http', 'ws$'];

  app.factory('wallOfWins', controller);

  function controller($q, _http, _ws$) {
    return {
      collection(o) {
        const defer = $q.defer(),
          alias = 'wallOfWins',
          config = {
            params: o || {},
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          },
        );

        return defer.promise;
      },
    };
  }
})();
