import account from "../../service/domain/account.js"
(function () {
  'use strict';

  controller.$inject = ['account'];

  const className = 'linda-has-preloader';

  function controller(_account) {
    function link(scope, element) {
      const setClass = (force) => element[0].classList.toggle(className, force);

      element.on('click', () => {
        setClass(true);

        _account
          .info({ nocache: true })
          .catch(() => {})
          .finally(() => setClass(false));
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive('lindaProfileRefreshOnClick', controller);
})();
