import tournament from '../../../service/domain/tournament.js';
(function () {
  'use strict';

  const component = { name: 'lindaWidgetTournaments' };

  controller.$inject = ['$scope', 'tournament'];

  function controller($scope, _tournament) {
    $scope.tournaments = [];
    $scope.preloader = true;

    _tournament.list().then(
      function (answer) {
        $scope.tournaments = answer.result;
        $scope.preloader = false;
      },
      function (answer) {
        $scope.preloader = false;
      }
    );
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
