import user from '../../service/domain/user.js';
import http from '../../service/domain/http.js';
(function () {
  'use strict';

  controller.$inject = ['$q', 'user', 'http'];

  function controller($q, _user, _http) {
    return {
      collection(options) {
        const defer = $q.defer(),
          alias = 'subscriptionList',
          config = {
            params: options,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },

      change(data) {
        const defer = $q.defer(),
          alias = 'subscriptionChange',
          config = {
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.post(alias, data, config).then(
          (answer) => {
            _user.profile = answer.data.result;
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },
    };
  }

  app.factory('subscription', controller);
})();
