import modal from '../../service/domain/modal.js';
(function () {
  'use strict';

  const component = { name: 'lindaModalBody' };

  controller.$inject = ['$scope', 'modal'];

  function controller($scope, _modal) {
    $scope.modal = _modal;

    $scope.$on('$includeContentLoaded', (e) => {});
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
