import helper from '../../../service/helper.js';
(function () {
  'use strict';

  const component = { name: 'lindaWidgetBonus' };

  controller.$inject = ['$scope', 'helper'];

  function controller($scope, _helper) {
    $scope.bonus = {};
    $scope.preloader = false;

    this.$onChanges = () => {
      $scope.bonus = this.bonus;

      $scope.activate = _helper.bonus.activate.bind($scope, this.bonus.bonus_id);
      $scope.cancel = _helper.bonus.cancel.bind($scope, this.bonus.bonus_id);
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
    bindings: {
      bonus: '<',
    },
  });
})();
