import bank from '../../service/domain/bank.js';
import helper from '../../service/helper.js';
import payment from '../../service/domain/payment.js';
import config from '../../service/configs/config.js';
import state from '../../service/domain/state.js';
import modal from '../../service/domain/modal.js';
import entercashConfig from '../../service/configs/entercash.js';
(function () {
  'use strict';

  const component = { name: 'swedenRegistrationBankPage' };

  controller.$inject = [
    '$scope',
    'bank',
    'helper',
    'payment',
    'config',
    '$timeout',
    'state',
    'modal',
    'entercash.config',
    '$state',
    '$element',
  ];

  function controller(
    $scope,
    _bank,
    _helper,
    _payment,
    _config,
    $timeout,
    _state,
    _modal,
    _entercash_config,
    $state,
    $element
  ) {
    $scope.flowType = _bank.entercash.formData.flowType;

    $scope.$watch('page', (newVal, oldVal) => {
      $element[0].classList.toggle('linda-step-2', newVal === 2);
    });

    $scope.defaultAmountRange = _entercash_config.defaultAmountRange['sv'];
    $scope.amountRange = _entercash_config.amountRange['sv'];

    $scope.formData = {
      currency: 'SEK',
      amount: _entercash_config.defaultAmount['sv'],
    };

    $scope.page = 1;

    if (_bank.entercash.formData.amount !== null) {
      $scope.formData.amount = _bank.entercash.formData.amount;
      $scope.flowType = 'signup_dep';
      $scope.page = 2;
      _bank.entercash.formData.amount = null;
    }

    if ($scope.flowType !== 'signup_dep') {
      $scope.page = 2;
    }

    $element[0].classList.add('linda-' + $scope.flowType.replace('_', '-'));

    $scope.submit = _helper.form.validator((form) => {
      $scope.page = 2;
    });
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
