import helper from '../../service/helper.js';
(function () {
  'use strict';

  const directive = { name: 'lindaOdometer' };

  controller.$inject = ['helper'];

  function controller(_helper) {
    function link(scope, element, attrs) {
      const od = new Odometer({
        el: element[0],
        value: 1000000,
        theme: 'default',
        duration: 5000,
        format: '( ddd).dd',

        selector: '.odometer',
      });

      const cancel = attrs.$observe(directive.name, (val) => {
        if (val > 0) {
          cancel();

          const interval = setInterval(() => {
            if (_helper.dom.isElementInViewport(element[0])) {
              clearInterval(interval);
              od.update(val);
            }
          }, 100);
        }
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
