import config from '../../service/configs/config.js';
(function () {
  'use strict';

  const directive = { name: 'lindaPwa' };

  controller.$inject = ['config'];

  function controller(_config) {
    function link(scope, element, attrs) {
      if (localStorage.getItem('pwa') || location.search.indexOf('standalone') + 1) {
        _config.pwa = true;
        element[0].classList.add('linda-pwa');
        localStorage.setItem('pwa', 'true');
      }
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
