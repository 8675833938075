import helper from '../../../service/helper.js';
import config from '../../../service/configs/config.js';
import entercashConfig from '../../../service/configs/entercash.js';
import state from '../../../service/domain/state.js';
(function () {
  'use strict';

  const component = { name: 'lindaBannerEntercash' };

  controller.$inject = ['$scope', 'helper', 'config', 'entercash.config', 'state', '$state'];

  function controller($scope, _helper, _config, _entercash_config, _state, $state) {
    $scope.currencyList = _entercash_config.currencyList;
    const defaultAmount = _entercash_config.defaultAmount;

    $scope.formData = {
      amount: defaultAmount[_config.currentLang],
      currency: $scope.currencyList[_config.currentLang].key,
    };

    $scope.defaultAmountRange = _entercash_config.defaultAmountRange[_config.currentLang];
    $scope.amountRange = _entercash_config.amountRange[_config.currentLang];

    $scope.submit = _helper.form.validator((form) => {
      const state = _state.get('fastRegistration');

      if (state) {
        $state.go(state.name, {
          amount: $scope.formData.amount,
          currency: $scope.formData.currency,
        });
      }
    });
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
