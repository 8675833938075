import user from '../../service/domain/user.js';
(function () {
  'use strict';

  const component = { name: 'lindaVipItem' };
  controller.$inject = ['$scope', '$element', 'user', '$timeout'];

  function controller($scope, $element, _user, $timeout) {
    $scope.lvl = {};

    this.$onChanges = () => {
      $scope.lvl = this.lvl;
      if (_user.status) $element[0].classList.toggle('current', _user.profile.level === this.lvl.level);
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
    bindings: {
      lvl: '<',
    },
  });
})();
