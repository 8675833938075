import http from '../../service/domain/http.js';
(function () {
  controller.$inject = ['$q', 'http'];

  function controller($q, _http) {
    return {
      sendInfoMessage(options) {
        const defer = $q.defer();
        const alias = 'infoToZendesk';
        const data = { options };
        const config = {
          meta: {
            type: 'api',
            alias,
          },
        };

        _http.post(alias, data, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          },
        );

        return defer.promise;
      },
    };
  }

  app.factory('zendesk', controller);
})();
