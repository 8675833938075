import vip from '../service/domain/vip.js';
(function () {
  'use strict';

  const directive = { name: 'getVipsToScope' };

  factory.$inject = ['vip'];

  function factory(_vip) {
    function link(scope, element, attrs) {
      const variable = attrs[directive.name] || 'vips';

      scope[variable] = {};

      _vip
        .collection()
        .then(({ result }) => {
          scope[variable] = result;
        })
        .catch((e) => {});
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, factory);
})();
