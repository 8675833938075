import system from '../../service/domain/system.js';

(function () {
  'use strict';

  const directive = {name: 'lindaAvatarSlider'};

  factory.$inject = ['system'];

  function factory(_system) {
    function compile() {
      return function ($scope, $element, $attrs, $ctrl, $transclude) {
        Promise.all([import('jquery'), import('slick-carousel')]).then(([$]) => {
          const slickBox = $($element[0].parentNode);
          const config = Object.assign(
            {
              initialSlide: 0,
            },
            $scope.$eval($attrs.sliderConfig),
          );

          const update = (list, index) => {
            $scope.avatarObject = list[index] || {};
            $scope.ngModel = $scope.avatarObject.alias || '';
          };

          _system
            .avatars()
            .then((a) => {
              const list = a.result;

              if (list.length) {
                for (let i = 0; i <= list.length - 1; i++) {
                  $transclude($scope.$new(true), (clone, scope) => {
                    scope.index = i;
                    scope.slide = list[i];
                    slickBox.append(clone[0]);
                  });
                }

                if ($scope.ngModel) {
                  const index = list.findIndex((i) => i.alias === $scope.ngModel);
                  if (index !== -1) {
                    config.initialSlide = index;
                  }
                }

                update(list, config.initialSlide);

                slickBox.slick(config);

                slickBox.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
                  $scope.$apply(() => {
                    update(list, nextSlide);
                  });
                });
              }
            })
            .catch((e) => {
            })
            .finally(() => {
            });
        });
      }
    }

    return {
      restrict: 'A',
      transclude: 'element',
      compile,
      scope: {
        ngModel: '=',
        avatarObject: '=?',
      },
    };
  }

  app.directive(directive.name, factory);
})();
