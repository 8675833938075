import http from '../../../service/domain/http.js';
(function () {
  'use strict';

  controller.$inject = ['http', '$q'];

  function controller(_http, $q) {
    let _teamList, _activeTeamList, _completedTeamList;

    return {
      object: {
        set list(data) {
          _teamList = data || [];

          _activeTeamList = [];
          _completedTeamList = [];

          _teamList.forEach((i) => {
            switch (i.status) {
              case 'active':
                _activeTeamList.push(i);
                break;
              case 'completed':
                _completedTeamList.push(i);
            }
          });

          _activeTeamList.sort((a, b) => b.progress.current - a.progress.current);
        },
        get list() {
          return _teamList;
        },
        get active() {
          return _activeTeamList;
        },
        get completed() {
          return _completedTeamList;
        },
      },

      list(options) {
        const defer = $q.defer(),
          alias = 'sportTeam',
          config = {
            params: options,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          (answer) => {
            this.object.list = answer.data.result;
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },

      item(options) {
        const defer = $q.defer(),
          alias = 'sportTeam',
          config = {
            params: options,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },
    };
  }

  app.factory('team', controller);
})();
