import http from '../../service/domain/http.js';
import user from '../../service/domain/user.js';
import trustly$ from '../../service/rx/trustly$.js';
import zignsecConfig from '../../service/configs/zignsec.js';
(function () {
  'use strict';

  app.factory('zignsec.paynplay', controller);

  controller.$inject = ['http', '$q', 'user', 'trustly$', 'zignsec.config'];

  function controller(_http, $q, _user, _trustly$, _zignsec_config) {
    const adformcookieid = window.Adform?.['_uid'];
    function requestParams(p) {
      const ls = JSON.parse(window.localStorage.getItem('params') || '{}');

      ls.paymethod = _zignsec_config.paymethod || 'zignsec';
      ls.currency = _zignsec_config.currency;
      ls.affiliate = {};
      ls.locale = _zignsec_config.locale;
      ls.amount = o.data.amount;
      ls.personId = o.data.personId;

      if (o.data.order_id) ls.order_id = o.data.order_id;

      return Object.assign({}, ls, p);
    }

    function reset() {
      o.data.flow = null;
      o.data.amount = _zignsec_config.default;
      o.data.personId = '';
      o.data.step = 0;
      o.data.order_id = null;
    }

    let _step = 0,
      _flow = null; // 'fast_deposit' - default || 'resume_play' - optional

    const o = {
      data: {
        order_id: null,
        amount: _zignsec_config.default,
        personId: '',
        reset,
      },

      init(params) {
        const defer = $q.defer(),
          alias = 'trustlyInit',
          config = { meta: { alias } };
        const data = requestParams(params);
        if (adformcookieid) {
          data.adformcookieid = adformcookieid;
        }
        Object.assign(o.data, data);

        _http.post(alias, data, config).then(
          (a) => {
            this.data.order_id = a.data.result.order_id || null;

            defer.resolve(a.data);
          },
          (a) => {
            defer.reject(a.data);
          }
        );

        return defer.promise;
      },

      auth(params) {
        const defer = $q.defer(),
          alias = 'trustlyAuth',
          config = {
            meta: { alias },
          };
        const data = requestParams(params);
        if (adformcookieid) {
          data.adformcookieid = adformcookieid;
        }
        _http.post(alias, data, config).then(
          (a) => {
            _user.profile = a.data.result;
            defer.resolve(a.data);
          },
          (a) => {
            defer.reject(a.data);
          }
        );

        return defer.promise;
      },

      checkStatus(options) {
        let defer = $q.defer(),
          alias = 'trustlyStatus',
          config = {
            params: options,
            cache: false,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data.result);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },

      updateProfile(data) {
        let defer = $q.defer(),
          alias = 'trustlyData',
          config = { meta: { alias } };

        _http.post(alias, data, config).then(
          (answer) => {
            defer.resolve(answer.data.result);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },
    };

    Object.defineProperty(o.data, 'step', {
      get() {
        return _step;
      },
      set(value) {
        _step = value;
        _trustly$.next({ action: 'step', data: value });
      },
    });

    Object.defineProperty(o.data, 'flow', {
      get() {
        return _flow || 'fast_deposit';
      },
      set(value) {
        _flow = value;
      },
    });

    return o;
  }
})();
