import history from '../../../service/domain/history.js';
import withdraw from '../../../service/domain/withdraw.js';
(function () {
  'use strict';

  const component = { name: 'lindaCashboxWithdrawCancel' };

  controller.$inject = ['$rootScope', '$scope', 'history', 'withdraw'];

  function controller($rootScope, $scope, _history, _withdraw) {
    $scope.preloader = false;
    $scope.collection = [];

    $scope.filter = {
      type: 'withdraw',
      status: 'pending',
    };

    $scope.cancel = (id) => {
      $scope.preloader = true;
      _withdraw.cancel({ alias: id }).then(
        (answer) => {
          $scope.collection = $scope.collection.filter((item) => {
            return item.id !== id;
          });
          _withdraw.cancelWithdrawal$.next();
          $scope.preloader = false;
        },
        (answer) => {
          $scope.preloader = false;
        }
      );
    };

    $scope.renew = () => {
      const opt = {
        params: $scope.filter,
        endpoint: 'payment',
      };

      $scope.preloader = true;
      _history.collection(opt).then(
        (answer) => {
          $scope.preloader = false;
          $scope.collection = answer.result;
        },
        (answer) => {
          $scope.preloader = false;
        }
      );
    };

    $scope.renew();

    $rootScope.$on('withdraw-new', $scope.renew);
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
  });
})();
