import click$ from '../service/rx/click$.js';
(function () {
  'use strict';

  controller.$inject = ['click$'];

  function controller(_click$) {
    function link(scope, element, attrs) {
      const config = scope.$eval(attrs.removeClassOnClickoutside);

      _click$.subscribe(function (e) {
        angular.element(document.querySelectorAll(config.selector)).removeClass(config.class);
      });

      element.bind('click', (e) => {
        e.stopPropagation();
      });
    }

    return {
      restrict: 'A',
      link: link,
    };
  }

  app.directive('removeClassOnClickoutside', controller);
})();
