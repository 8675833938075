import modal from "../service/domain/modal.js"
// (terms-modal-open="{ terms: item.terms }")
// (terms-modal-open="{ alias: 'cards' }")

(function () {
  const name = 'termsModalOpen';

  controller.$inject = ['modal', '$timeout'];

  function controller(_modal, $timeout) {
    function link(scope, element, attrs) {
      element.on('click', () => {
        $timeout(() => {
          _modal.open('terms', scope.$eval(attrs[name])).catch(() => {});
        });
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(name, controller);
})();
