import payment from '../../../service/domain/payment.js';
import user from '../../../service/domain/user.js';
import config from '../../../service/configs/config.js';
import helper from '../../../service/helper.js';
import system$ from '../../../service/rx/system$.js';
import modal from '../../../service/domain/modal.js';
(function () {
  const component = { name: 'lindaCashboxDepositForm' };

  controller.$inject = ['$scope', 'payment', '$element', 'user', 'config', 'helper', 'system$', '$window', 'modal'];

  function controller($scope, _payment, $element, _user, _config, _helper, _system$, $window, _modal) {
    const localBet = parseInt($window.localStorage.getItem('saveDepAmount'));
    let wnd = {};

    $scope.preloader = false;
    $scope.system = {};
    $scope.formData = {
      amount: 0,
    };

    $scope.checkPendingWithdrawals = false;
    $scope.showMinMaxWarning = false;

    this.$onChanges = () => {
      if (typeof this.system !== 'object') return;

      this.system.rules.showAmount = _payment.hasAmount(this.system.rules.system) && !this.system.hide_amount;

      $scope.system = this.system;
      $scope.formData.place = this.place || 'cashbox';
      $scope.formData.paymethod = this.system.rules.system;
      $scope.formData.amount = this.amount || localBet || this.system.rules.default;
      $scope.formData.paysystem_id = this.system.id;
  
      if(this.amount && (this.system.min_amount > this.amount || this.system.max_amount < this.amount )){
        $scope.showMinMaxWarning = true;
      }
    };

    function messageHandler({ data }) {
      if (data === 'close') {
        wnd.close();
        wnd = {};
        return;
      }

      if (data === 'open-chat') {
        wnd.close();
        wnd = {};
        _system$.next({ action: 'open chat' });
        return;
      }
    }

    window.onmessage = messageHandler;

    function submitForm() {
      if (wnd?.closed === false) {
        return wnd.focus();
      }

      const width = 800;
      const height = 500;
      const left = (window.innerWidth - width) / 2;
      const top = (window.innerHeight - height) / 2;

      const checker = setInterval(() => {
        if (wnd.closed) {
          wnd = {};
          clearInterval(checker);
        }
      }, 100);

      wnd = _config.browser.webView
        ? window
        : window.open(
            `/preloader.html?login=${_user.profile.login}`,
            'depositWindow',
            `height=${height},width=${width},left=${left},top=${top}`
          );

      if (!_config.device.mobile) {
        $scope.preloader = true;
      }

      _payment
        .deposit($scope.formData)
        .then(({ result: { url, post, method } }) => {
          if (method.toLowerCase() === 'post') {
            return _payment.makeForm(url, post, $element);
          }

          wnd.document.location = url;
        })
        .catch(() => {
          wnd.document.location = '/' + _config.currentLang + `/payment/fail?login=${_user.profile.login}`;
        })
        .finally(() => {
          $scope.preloader = false;
        });
    }

    $scope.submit = _helper.form.validator(() => {
      $scope.showMinMaxWarrning = false;
      if ($scope.checkPendingWithdrawals) {
        _modal
          .open('cancel-pending-withdrawals')
          .then(() => {
            submitForm();
          })
          .catch(() => {})
          .finally(() => {});
        return;
      }
      submitForm();
    });
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
    bindings: {
      system: '<',
      place: '<',
      amount: '<',
    },
  });
})();
