import system$ from '../../service/rx/system$.js';
import verificationInfo$ from '../../service/domain/documentVerification.js';
import user from '../../service/domain/user.js';
import localStorage from '../../service/localStorage.js';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

(function () {
  const component = {
    name: 'dvsAlert',
  };

  controller.$inject = ['$scope', '$document', '$element', 'system$', 'verificationInfo$', 'user', 'localStorage'];

  function controller($scope, $document, $element, _system$, _verificationInfo$, _user, _localStorage) {
    const destroy$ = new Subject();
    const PARAMS = {
      localStorageKey: 'show-verification-bar',
      bodyHasNotificationClass: 'has-dvs-verification',
      verificationPageAlias: 'verification',
    };

    this.$onChanges = () => {
      PARAMS.verificationPageAlias = this.verificationPageAlias || PARAMS.verificationPageAlias;
      PARAMS.localStorageKey = this.localStorageKey || PARAMS.localStorageKey;
      PARAMS.bodyHasNotificationClass = this.bodyHasNotificationClass || PARAMS.bodyHasNotificationClass;

      $scope.verificationPageAlias = PARAMS.verificationPageAlias;
    };

    function changeVerificationStatus() {
      $scope.isVerificationBlocked = !!_user.profile.groups?.length;
      $scope.verificationStatus = _user.profile.verificationStatus;
      $scope.showVerificationBar = _localStorage.get(PARAMS.localStorageKey);

      $document[0].body.classList.remove(PARAMS.bodyHasNotificationClass);

      switch ($scope.verificationStatus) {
        case 'VERIFIED':
          if ($scope.showVerificationBar) {
            $document[0].body.classList.add(PARAMS.bodyHasNotificationClass);
          }

          break;
        case 'REQUIRES_VERIFICATION':
          _localStorage.set(PARAMS.localStorageKey, true);
          $document[0].body.classList.add(PARAMS.bodyHasNotificationClass);

          break;
        default:
          $document[0].body.classList.remove(PARAMS.localStorageKey);
          break;
      }
    }

    $scope.close = () => {
      $document[0].body.classList.remove(PARAMS.bodyHasNotificationClass);
      _localStorage.remove(PARAMS.localStorageKey);

      $element.remove();
    };

    changeVerificationStatus();

    _system$
      .pipe(
        takeUntil(destroy$),
        filter(({ action }) => action === 'statusChanged'),
      )
      .subscribe(changeVerificationStatus);

    _verificationInfo$
      .pipe(
        takeUntil(destroy$),
        filter(({ action }) => action === 'verificationStatusUpdated'),
      )
      .subscribe(changeVerificationStatus);

    $scope.$onDestroy = () => {
      destroy$.next();
      destroy$.unsubscribe();
    };
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
    bindings: {
      verificationPageAlias: '<',
      localStorageKey: '<',
      bodyHasNotificationClass: '<',
    },
  });
})();
