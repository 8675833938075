import system from '../../../service/domain/system.js';
(function () {
  'use strict';

  const directive = { name: 'removeElementIfNotPaynplay' };

  factory.$inject = ['system'];

  function factory(_system) {
    function link(scope, element, attrs) {
      if (!_system.mode.paynplay) element[0].parentNode.removeChild(element[0]);
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, factory);
})();
